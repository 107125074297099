import { useCallback, useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import strings from "../../../localization/strings";
import Form from "../../core/Form/Form/Form";
import styles from "./EmbeddedCodes.module.scss";
import { getIframeLink } from "../../../utils.ts/Urls";
import { toastSuccess } from "../../../utils.ts/Toaster";
import { TagsContext } from "../../../contexts/TagsContext";
import FormMultiDataListInput from "../../core/Form/FormMultiDataListInput/FormMultiDataListInput";
import { GetSearchParamsFromFilters, NamedOptionsFilter } from "../../../hooks/UseSearchParam";
import { FilterSpecs } from "../../../hooks/UseSearchParam";
import FormCheckbox from "../../core/Form/FormCheckbox/FormCheckbox";
import { useOrganiserOptions } from "../../../hooks/TagHooks";

export default function EmbeddedCodes() {
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const tags = useContext(TagsContext).allTags;
    const organiserOptions = useOrganiserOptions();
    const organiserInputOptions = useMemo(
        () => organiserOptions.map((group) => ({ label: group.detailedName, value: group.id })),
        [organiserOptions],
    );
    const filterSpecs: FilterSpecs = useMemo(
        () => ({
            groups: new NamedOptionsFilter(tags.happeningGroups.concat(tags.partners)),
        }),
        [tags],
    );

    const generateAndCopyEmbeddedCode = useCallback(
        async (filters: { groups: string[]; showFilters: boolean }) => {
            const preparedFilters = {
                groups: filters.groups.map((g) => ({ name: g })),
            };

            const param = filters.showFilters ? new URLSearchParams({ filters: "1" }) : undefined;
            const filterString = GetSearchParamsFromFilters(filterSpecs, preparedFilters, param).toString();

            // @NOTE(Lejun) use js to dynamically handle iframe height
            var code = `
<script>
    if (window.addEventListener) {
        window.addEventListener("message", sbgIframeMessageHandler);
    } else {
        window.attachEvent("onmessage", sbgIframeMessageHandler);
    }

    function sbgIframeMessageHandler(event) {
        const sbgCalendarIframe = document.querySelector("#sbg-iframe-calendar-widget")
        const isSBGComm = event.source === sbgCalendarIframe.contentWindow;
        if (!sbgCalendarIframe || !isSBGComm) {
            return;
        }
        sbgCalendarIframe.height = event.data.height;
        if (event.data.isInit) {
            sbgCalendarIframe.scrollIntoView({behavior: "smooth"});
        }
    }
</script>
<iframe id="sbg-iframe-calendar-widget" src="${getIframeLink(
                filterString,
            )}" frameBorder="0" width="100%" height="0" allow="clipboard-write"></iframe>
            `;
            await navigator.clipboard.writeText(code);
            toastSuccess(strings.confirmCopyEmbedCode);
        },
        [filterSpecs],
    );

    return (
        <div className={styles.embeddedCodeFormContainer}>
            <Form submitText={strings.copyEmbedCode} onSubmit={generateAndCopyEmbeddedCode} handleSubmit={handleSubmit}>
                <div className={styles.section}>
                    <div className={styles.halfSection}>
                        <div className={styles.dropdownContainer}>
                            <FormMultiDataListInput
                                name="groups"
                                options={organiserInputOptions}
                                control={control}
                                label={strings.happeningGroups}
                                errors={errors.happeningGroups}
                                list="happeningGroups"
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.section}>
                    <div className={styles.halfSection}>
                        <FormCheckbox name="showFilters" control={control} label={strings.showFilters} />
                    </div>
                </div>
            </Form>
        </div>
    );
}

import { isChiefEditor } from "../authentication/UserMethods";
import strings from "../localization/strings";
import { UserOutput, UserSubset } from "../openapi/backend";
import { HappeningSubsetOption } from "../types/DropdownOption";
import { HappeningSubset } from "../types/HappeningSubset";

export function getTargetGroupOptions(user: UserOutput, current: UserSubset | undefined) {
    if (isChiefEditor(user)) {
        return [
            getLabelForTargetGroup(UserSubset.AllOrganisations),
            getLabelForTargetGroup(UserSubset.InternalOrganisations),
            getLabelForTargetGroup(UserSubset.PartnerOrganisations),
            getLabelForTargetGroup(UserSubset.SelectedOrganisations),
        ];
    }
    if (current !== undefined && current !== UserSubset.SelectedOrganisations) {
        return [getLabelForTargetGroup(current), getLabelForTargetGroup(UserSubset.SelectedOrganisations)];
    }
    return [getLabelForTargetGroup(UserSubset.SelectedOrganisations)];
}

function getLabelForTargetGroup(userSubset: UserSubset) {
    switch (userSubset) {
        case UserSubset.AllOrganisations:
            return { label: strings.allOrganisations, value: UserSubset.AllOrganisations };
        case UserSubset.InternalOrganisations:
            return { label: strings.allInternalOrganisations, value: UserSubset.InternalOrganisations };
        case UserSubset.Participants:
            return { label: strings.participants, value: UserSubset.Participants };
        case UserSubset.PartnerOrganisations:
            return { label: strings.allPartnerOrganisations, value: UserSubset.PartnerOrganisations };
        case UserSubset.SelectedOrganisations:
            return { label: strings.selectOrganisations, value: UserSubset.SelectedOrganisations };
        case UserSubset.NotVisibleForParticipation:
            return { label: strings.notVisibleForParticipation, value: UserSubset.NotVisibleForParticipation };
    }
}

export function getHappeningSubsetOptions(exclude: HappeningSubset[] = []): HappeningSubsetOption[] {
    return Object.values(HappeningSubset)
        .filter((value) => !exclude.includes(value as HappeningSubset))
        .map((value) => ({
            name: value as keyof typeof HappeningSubset,
            value: HappeningSubset[value as keyof typeof HappeningSubset],
        }));
}

export function happeningSubsetToString(target: HappeningSubset): string {
    return getStringForHappeningSubset(target);
}

function getStringForHappeningSubset(target: HappeningSubset) {
    switch (target) {
        case HappeningSubset.Participant:
            return strings.happeningSubsetEnumParticipant;
        case HappeningSubset.NotVisible:
            return strings.happeningSubsetEnumNotVisible;
        case HappeningSubset.Private:
            return strings.happeningSubsetEnumPrivate;
        case HappeningSubset.Public:
            return strings.happeningSubsetEnumPublic;
        case HappeningSubset.Clustered:
            return strings.happeningSubsetEnumClustered;
        case HappeningSubset.Accessible:
            return strings.happeningSubsetEnumAccessible;
        case HappeningSubset.OrganizedByPartner:
            return strings.happeningSubsetEnumOrganizedByPartner;
        case HappeningSubset.Internal:
            return strings.happeningSubsetEnumInternal;
        default:
            return strings.unknown;
    }
}

import { useInternalHappeningsOverview } from "../../hooks/HappeningsHooks";
import { HappeningFilterKeys } from "../../types/HappeningFilterKeys";
import { HappeningSubset } from "../../types/HappeningSubset";
import HappeningsTable from "./HappeningsTable";

const excludeFilters: HappeningFilterKeys = ["happeningTypes"];
const excludeHappeningSubsets: HappeningSubset[] = [
    HappeningSubset.Participant,
    HappeningSubset.NotVisible,
    HappeningSubset.Internal,
];

export default function InternalHappeningsContainer() {
    const [happeningsState, getInternalHappenings] = useInternalHappeningsOverview();

    return (
        <HappeningsTable
            happenings={happeningsState.value}
            getHappenings={getInternalHappenings}
            error={happeningsState.error}
            excludeFilters={excludeFilters}
            excludeHappeningSubsets={excludeHappeningSubsets}
        />
    );
}

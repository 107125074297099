import strings from "../../localization/strings";
import StateLabel from "../core/StateLabel/StateLabel";
import GroupIcon from "../core/AvatarIcon/GroupIcon";
import Table from "../core/Table/Table";
import { ReactNode, useMemo, useCallback, useState, useEffect, useContext } from "react";
import {
    HappeningListItemViewModel,
    HappeningListItemViewModelPaginatedViewModel,
    HappeningOrderBy,
    HappeningTimeAndDateViewModel,
    HappeningType,
    SortOrder,
    TagType,
    UserSubset,
} from "../../openapi/backend";
import { Column } from "react-table";
import moment from "moment";
import styles from "./HappeningsTable.module.scss";
import ProfileContent from "../core/ProfileContent/ProfileContent";
import {
    GetHappeningsFun,
    HappeningFilter,
    useHappeningDelete,
    useHappeningDetails,
} from "../../hooks/HappeningsHooks";
import HappeningFilterBar from "../core/FilterBar/HappeningFilterBar";
import ErrorMessage from "../core/ErrorMessage/ErrorMessage";
import { TagsContext } from "../../contexts/TagsContext";
import { useProjectLeaderOptions } from "../../hooks/UserHooks";
import { UserNamedType } from "../../types/UserNamedType";
import { getName } from "../../utils.ts/GetName";
import {
    DateFilter,
    FilterSpecs,
    NamedOptionsFilter,
    PageNumberFilter,
    StringFilter,
    useFilters,
} from "../../hooks/UseSearchParam";
import { getStateOptions } from "../../utils.ts/StateOptions";
import { RequestState } from "../../hooks/UseApiCall";
import { HappeningFilterKeys } from "../../types/HappeningFilterKeys";
import { useNavigate } from "react-router";
import {
    createHappeningLink,
    createPrivateHappeningLink,
    getHappeningLink,
    getUpdateHappeningLink,
} from "../../utils.ts/Urls";
import AccessibilityIcon from "../../assets/accessibility_icon.svg";
import NoDateIcon from "../../assets/icon_no_date.svg";
import PartnerIcon from "../../assets/icon_parteractivity.svg";
import ExternalIcon from "../../assets/external_link_icon.svg";
import LockIcon from "../../assets/lock_icon.svg";
import ClusterIcon from "../../assets/cluster_icon.svg";
import HiddenIcon from "../../assets/hidden_icon.svg";
import { getLocationTypeString } from "../../utils.ts/GetLocationTypeString";
import { getHappeningSubsetOptions } from "../../utils.ts/TargetGroupOptions";
import { canCancelHappening, getRelevantSession } from "../../utils.ts/Happening";
import { HappeningSubset } from "../../types/HappeningSubset";
import EllipsisMenu from "../core/EllipsisMenu/EllipsisMenu";
import ActionDuplicate from "../../assets/action_duplicate_icon.svg";
import ActionEdit from "../../assets/action_edit_icon.svg";
import {
    canCancelAHappeningOverview,
    canCloneHappening,
    canDeleteHappeningOverview,
    canEditHappeningOverview,
} from "../../authentication/Permissions";
import { ProfileContext } from "../../contexts/ProfileContext";
import ActionDelete from "../../assets/action_delete_icon.svg";
import CancelHappeningModal from "../Forms/ViewHappening/CancelHappeningModal";
import ConfirmationModal from "../core/ConfirmationModal/ConfirmationModal";
import ActionCancel from "../../assets/action_close_icon.svg";
import { HappeningCloneContext } from "../../contexts/HappeningCloneContext";

const getDate = (dateTimes: HappeningTimeAndDateViewModel[], minimumDate?: Date, maximumDate?: Date) => {
    const relevantDateTime = getRelevantSession(dateTimes, minimumDate, maximumDate);
    return relevantDateTime ? `${moment(relevantDateTime.sessionStart).format("DD/MM/YYYY")}` : "-";
};

export interface HappeningTableItem {
    Name: ReactNode;
    Id: string;
    Date: ReactNode;
    ProjectLeader: ReactNode;
    State: ReactNode;
    HappeningGroup: ReactNode;
    Location: ReactNode;
    Participants: ReactNode;
    Actions: ReactNode;
}

interface AllHappeningsProps {
    happenings?: HappeningListItemViewModelPaginatedViewModel;
    getHappenings: GetHappeningsFun;
    error?: Response;
    excludeFilters?: HappeningFilterKeys;
    excludeHappeningSubsets?: HappeningSubset[];
}

// Default sort
const defaultOrderBy = HappeningOrderBy.Date;
const defaultSortOrder = SortOrder.Ascending;

const stateOptions = getStateOptions();
const happeningSubsetOptions = getHappeningSubsetOptions();

const columns: Column<HappeningTableItem>[] = [
    {
        Header: strings.happening,
        accessor: "Name",
        width: "20%",
    },
    {
        Header: strings.date,
        accessor: "Date",
        width: "10%",
    },
    {
        Header: strings.projectLeader,
        accessor: "ProjectLeader",
    },
    {
        Header: strings.happeningGroup,
        accessor: "HappeningGroup",
        width: "10%",
    },
    {
        Header: strings.location,
        accessor: "Location",
    },
    {
        Header: strings.participants,
        accessor: "Participants",
        width: "15%",
    },
    {
        Header: strings.state,
        accessor: "State",
        width: "15%",
    },
    {
        Header: strings.actions,
        accessor: "Actions",
        disableSortBy: true,
        width: 20,
    },
];

export default function HappeningsTable({
    happenings,
    getHappenings,
    error,
    excludeFilters,
    excludeHappeningSubsets,
}: AllHappeningsProps) {
    const { profile } = useContext(ProfileContext);
    const [orderBy, setOrderBy] = useState(defaultOrderBy);
    const [sortOrder, setSortOrder] = useState(defaultSortOrder);
    const [itemsPerPage] = useState(10);
    const navigate = useNavigate();
    const [deleteTarget, setDeleteTarget] = useState<HappeningListItemViewModel | undefined>(undefined);
    const [cancelFormOpen, setCancelFormOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState<number>();
    const [happeningData, setHappeningData] = useState<HappeningListItemViewModel>();
    const [deleteHappeningState, deleteHappening, resetDeleteRequest] = useHappeningDelete(happeningData?.id);
    const { value: cloneHappening } = useHappeningDetails(happeningData?.id || "");
    const { setHappening: setHappeningToClone } = useContext(HappeningCloneContext);
    const [isReadyToClone, setIsReadyToClone] = useState(false);

    const { value: projectLeaders, state: projectLeadersState } = useProjectLeaderOptions();
    const leaders: UserNamedType[] = useMemo(
        () => (projectLeaders ? projectLeaders.map((leader) => ({ ...leader, name: getName(leader) })) : []),
        [projectLeaders],
    );
    const tags = useContext(TagsContext).allTags;

    const filterSpecs: FilterSpecs = useMemo(
        () => ({
            query: new StringFilter(),
            fromDate: new DateFilter(),
            toDate: new DateFilter(),
            groups: new NamedOptionsFilter(tags.happeningGroups.concat(tags.partners)),
            happeningTypes: new NamedOptionsFilter(tags.happeningTypes),
            states: new NamedOptionsFilter(stateOptions),
            projectLeaders: new NamedOptionsFilter(leaders),
            happeningSubsets: new NamedOptionsFilter(happeningSubsetOptions),
            page: new PageNumberFilter(),
        }),
        [tags, leaders],
    );
    const [rawFilters, setFilters] = useFilters(filterSpecs);
    const filters = rawFilters as HappeningFilter;

    const onFilterChange = useCallback(
        (filter: HappeningFilter) => {
            setFilters({ ...filter, page: 1 });
        },
        [setFilters],
    );

    const setPage = useCallback(
        (p: number) => {
            setFilters({ ...filters, page: p });
        },
        [filters, setFilters],
    );

    const loadHappenings = useCallback(() => {
        // Wait until the project leaders options have been loaded, because the filters are dependent on them.
        // Otherwise, we get multiple getHappenings calls in quick succession which causes a race condition
        // (the last response to come in will be the "winner").
        // If the project leader call fails, start loading regardless.
        const loadingLeadersDone = leaders.length > 0 || projectLeadersState === RequestState.ERROR;

        if (loadingLeadersDone) {
            return getHappenings(orderBy, sortOrder, itemsPerPage, filters);
        }
    }, [getHappenings, leaders, orderBy, sortOrder, itemsPerPage, filters, projectLeadersState]);

    useEffect(() => {
        loadHappenings();
    }, [loadHappenings]);

    useEffect(() => {
        if (cloneHappening && cloneHappening.id && isReadyToClone) {
            setHappeningToClone(cloneHappening);
            navigate(
                cloneHappening.type === HappeningType.PrivateHappening
                    ? createPrivateHappeningLink
                    : createHappeningLink,
            );
        }
    }, [cloneHappening, navigate, setHappeningToClone, isReadyToClone]);

    useEffect(() => {
        if (deleteHappeningState.state === RequestState.DONE) {
            loadHappenings();
            setDeleteTarget(undefined);
            resetDeleteRequest();
        }
    }, [loadHappenings, deleteHappeningState.state, resetDeleteRequest]);

    function getParticipantsString(happening: HappeningListItemViewModel) {
        return `${
            // Present or counted participants
            happening.type === HappeningType.PrivateHappening
                ? ""
                : // Find the number total number of present participants
                  Object.entries(happening.presenceStatistics || {})
                      .map(([_, value]) => value.present || 0)
                      .reduce((a, b) => a + b, 0)
                      .toString() + "/"
        }${
            // Total number of registrations
            happening.currentNumberOfRegistrations
        }/${
            // Max number of registrations
            happening.maxNumberOfRegistrations === 0 ? "∞" : happening.maxNumberOfRegistrations
        }`;
    }
    const data = useMemo<HappeningTableItem[]>(() => {
        return (
            happenings?.items?.map((happening) => {
                const hasRegisteredParticipants = happening.currentNumberOfRegistrations > 0 ? true : false;
                const canDelete = canDeleteHappeningOverview(happening, profile, hasRegisteredParticipants);
                const canEdit = canEditHappeningOverview(happening, profile);
                const canCancel = canCancelHappening(happening) && canCancelAHappeningOverview(happening, profile);

                const actions = [
                    canDelete && {
                        icon: ActionDelete,
                        onClick: () => {
                            setHappeningData(happening);
                            setDeleteTarget(happening);
                        },
                        alt: strings.formatString(strings.ellipsisMenuAltDelete, happening.title) as string,
                        title: strings.ellipsisMenuTitleDelete,
                    },
                    canCloneHappening(profile) && {
                        icon: ActionDuplicate,
                        onClick: () => {
                            setHappeningData(happening);
                            setIsReadyToClone(true);
                        },
                        alt: strings.formatString(strings.ellipsisMenuAltDuplicate, happening.title) as string,
                        title: strings.ellipsisMenuTitleDuplicate,
                    },
                    canEdit && {
                        icon: ActionEdit,
                        onClick: () => {
                            navigate(getUpdateHappeningLink(happening.id));
                        },
                        alt: strings.formatString(strings.ellipsisMenuAltEdit, happening.title) as string,
                        title: strings.ellipsisMenuTitleEdit,
                    },
                    canCancel && {
                        icon: ActionCancel,
                        onClick: () => {
                            setHappeningData(happening);
                            setCancelFormOpen(true);
                            setSelectedDate(undefined);
                        },
                        alt: strings.formatString(strings.ellipsisMenuAltEdit, happening.title) as string,
                        title: strings.cancel,
                    },
                ];
                return {
                    Name: (
                        <div className={styles.nameContainer}>
                            <div>{happening.title}</div>
                            {happening.isInternal && <img src={LockIcon} alt={strings.internalHappening} />}
                            {happening.metaData.isCluster && (
                                <img src={ClusterIcon} alt={strings.happeningPartOfCluster} />
                            )}
                            {happening.isAccessible && <img src={AccessibilityIcon} alt={strings.isAccessible} />}
                            {happening.targetGroup === UserSubset.NotVisibleForParticipation && (
                                <img src={HiddenIcon} alt={strings.happeningHiddenForParticipation} />
                            )}
                            {happening.dateTimes.length === 0 && happening.type === HappeningType.PrivateHappening && (
                                <img src={NoDateIcon} alt={strings.happeningHasNoDate} />
                            )}
                            {happening.happeningGroup && happening.happeningGroup.type === TagType.Partner && (
                                <img src={PartnerIcon} alt={strings.happeningGroupPartner} />
                            )}
                            {happening.type === HappeningType.ExternalHappening && (
                                <img src={ExternalIcon} alt={strings.happeningExternal} />
                            )}
                        </div>
                    ),
                    Id: happening.id,
                    Date:
                        happening.type === HappeningType.PrivateHappening
                            ? "-"
                            : getDate(happening.dateTimes, filters.fromDate, filters.toDate),
                    ProjectLeader: happening.shortProjectLeader ? (
                        <div className={styles.projectLeaderContainer}>
                            <ProfileContent profile={happening.shortProjectLeader} />
                        </div>
                    ) : (
                        <>-</>
                    ),
                    State: <StateLabel state={happening.state} />,
                    HappeningGroup: happening.happeningGroup ? <GroupIcon group={happening.happeningGroup} /> : <>-</>,
                    Location: happening.location?.name ?? getLocationTypeString(happening.locationType),
                    Participants: getParticipantsString(happening),
                    Actions: <EllipsisMenu items={actions} />,
                };
            }) ?? []
        );
    }, [happenings, filters.fromDate, filters.toDate, navigate, profile]);

    const handleSort = useCallback(
        (sortBy) => {
            // We only sort on 1 column so only take the first element in sortBy
            if (sortBy[0]) {
                setOrderBy(sortBy[0].id);
                setSortOrder(sortBy[0].desc ? SortOrder.Descending : SortOrder.Ascending);
            } else {
                // If no sorting is applied, revert back to default sorting
                setOrderBy(defaultOrderBy);
                setSortOrder(defaultSortOrder);
            }
        },
        [setOrderBy, setSortOrder],
    );

    return (
        <div>
            <HappeningFilterBar
                onChange={onFilterChange}
                value={filters}
                excludeFilters={excludeFilters}
                excludeHappeningSubsets={excludeHappeningSubsets}
            />

            {error && <ErrorMessage error={error} />}

            {!error && (
                <Table
                    columns={columns}
                    data={data}
                    emptyString={strings.noHappeningsFound}
                    setPage={setPage}
                    paginationMeta={happenings?.meta}
                    onSort={handleSort}
                    onRowClick={(happening: HappeningTableItem) => navigate(getHappeningLink(happening.Id))}
                    forcePage={filters.page}
                />
            )}
            {cancelFormOpen && happeningData && (
                <CancelHappeningModal
                    isOpen={cancelFormOpen}
                    onDismiss={() => setCancelFormOpen(false)}
                    onSuccess={loadHappenings}
                    happeningId={happeningData.id}
                    dateId={selectedDate}
                    isRecurrence={false}
                    dateTimes={happeningData.dateTimes}
                />
            )}
            {deleteTarget && (
                <ConfirmationModal
                    isOpen={true}
                    onCancel={() => setDeleteTarget(undefined)}
                    onConfirm={() => deleteHappening()}
                    isLoading={deleteHappeningState.state === RequestState.LOADING}
                    error={deleteHappeningState.error}
                    errorMessage={strings.errorRemoveIndicator}
                    confirmText={strings.yesDelete}
                >
                    {strings.formatString(strings.confirmDeleteIndicator, deleteTarget.title)}
                </ConfirmationModal>
            )}
        </div>
    );
}

import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import EuroSymbol from "../../../assets/euro_symbol.svg";
import { canOnlySuggestHappenings, canForceEditHappening } from "../../../authentication/Permissions";
import { isApprover, isChiefEditor, isEditor, isPartner } from "../../../authentication/UserMethods";
import {
    MAX_AGE,
    MAX_LINK_LABEL_LENGTH,
    MAX_TITLE_LENGTH,
    MIN_AGE,
    MIN_PRICE_VALUE,
    basicUrlHttps,
    selectHttps,
} from "../../../constants/Validation";
import { HappeningInput } from "../../../hooks/HappeningsHooks";
import { ApiCallState, RequestState, ApiFunction } from "../../../hooks/UseApiCall";
import strings from "../../../localization/strings";
import {
    DayOfWeek,
    HappeningRegistrationOutput,
    HappeningRegistrationType,
    HappeningTimeAndDateInput,
    HappeningType,
    ImageViewModel,
    InfoLevel,
    LocationType,
    MonthlyRecurrenceType,
    PaymentMethod,
    RecurrenceInput,
    RecurrenceType,
    TagViewModel,
    UpdateTarget,
    UserOutput,
    UserShortOutput,
    UserSubset,
} from "../../../openapi/backend";
import { HappeningState } from "../../../openapi/backend/models/HappeningState";
import { TagCollection } from "../../../openapi/backend/models/TagCollection";
import { getNthDayOfWeekString } from "../../../utils.ts/DateTime";
import { isSameTimeslot } from "../../../utils.ts/Happening";
import { InitialTimeAndDates } from "../../../utils.ts/InitialTimeAndDates";
import Accordion from "../../core/Accordion/Accordion";
import DateTimePickerContainer from "../../core/DateTimePicker/DateTimePickerContainer";
import Form from "../../core/Form/Form/Form";
import FormAddressInput from "../../core/Form/FormAddressInput/FormAddressInput";
import FormCheckbox from "../../core/Form/FormCheckbox/FormCheckbox";
import FormCheckboxGroup from "../../core/Form/FormCheckboxGroup/FormCheckboxGroup";
import FormDataListInput from "../../core/Form/FormDataList/FormDataListInput";
import LeaderDataListInput from "../../core/Form/FormDataList/LeaderDataListInput";
import FormDatepicker from "../../core/Form/FormDatePicker/FormDatePicker";
import FormField from "../../core/Form/FormField/FormField";
import FormFieldMultiline from "../../core/Form/FormFieldMultiline/FormFieldMultiline";
import FormMultiDataListInput from "../../core/Form/FormMultiDataListInput/FormMultiDataListInput";
import MemberDataListInput from "../../core/Form/FormMultiDataListInput/MemberDataListInput";
import FormRadioGroup from "../../core/Form/FormRadioGroup/FormRadioGroup";
import FormResponsibilityListInput from "../../core/Form/FormResponsibilityListInput/FormResponsibilityListInput";
import { FormBooleanSelect, FormStringSelect } from "../../core/Form/FormSelect/FormSelect";
import SearchDataListInput from "../../core/Inputs/SearchDataListInput/SearchDataListInput";
import SearchPlacesListInput from "../../core/Inputs/SearchPlacesListInput/SearchPlacesListInput";
import TextButton from "../../core/TextButton/TextButton";
import styles from "./HappeningForm.module.scss";
import { conceptValidationSchema, RecurrenceEndingType, validationSchema, FormData } from "./HappeningValidation";
import SaveOpenHappeningModal from "./SaveOpenHappeningModal";
import FloatingPublishModal from "../../core/FloatingPublishModal/FloatingPublishModal";
import CheckboxInput from "../../core/Inputs/CheckboxInput/CheckboxInput";
import { formatAsDate, formatAsTime } from "../../../utils.ts/Formatting";
import FormDateTimePicker from "../../core/Form/FormDateTimePicker/FormDateTimePicker";
import CancelModal from "./CancelModal";
import FormPageLayout from "../../FormPageLayout/FormPageLayout";
import { CrossButton } from "../../core/CrossButton/CrossButton";
import Switch from "../../core/Switch/Switch";
import { getTargetGroupOptions } from "../../../utils.ts/TargetGroupOptions";
import cx from "classnames";
import { getHappeningGroupOptions } from "../../../utils.ts/GetHappeningGroupOptions";
import FormMediaLibraryInput from "../../core/Form/FormMediaLibraryInput/FormMediaLibraryInput";
import { Operation } from "../../../types/Operation";
import FormMultiQuestionField from "../../core/Form/FormMultiQuestionField/FormMultiQuestionField";
import SubInputLayout from "../../core/Inputs/SubInputLayout/SubInputLayout";
import FormDurationInput from "../../core/Form/FormDurationInput/FormDurationInput";
import ContactDetails from "../../core/ContactDetails/ContactDetails";

interface HappeningFormProps<T> {
    title: string;
    onDismiss: () => void;
    onSaved: (response: T) => void;
    callback: () => [ApiCallState<T>, ApiFunction<T, any>, () => void];
    defaultValues: Partial<FormData>;
    tags: TagCollection;
    storedImages: ImageViewModel[];
    user: UserOutput;
    className?: string;
    type: HappeningType;
    operation: Operation;
    happeningId?: string;
    contact?: UserShortOutput;
    registrations: HappeningRegistrationOutput[];
}

const happeningResolver = (data: FormData, context: any, options: any) => {
    let schema;
    switch (true) {
        case data.state === HappeningState.Concept && !!data.publicationDate:
            schema = validationSchema;
            break;
        case data.state === HappeningState.Concept:
            schema = conceptValidationSchema;
            break;
        default:
            schema = validationSchema;
            break;
    }

    return yupResolver(schema)(data, context, options);
};

function getEndingType(recurrence?: RecurrenceInput): RecurrenceEndingType {
    switch (true) {
        case !!recurrence?.endDate:
            return "date";
        case recurrence?.repeatMax !== undefined:
            return "count";
    }
}

function getSubmitLabel(
    state: HappeningState,
    user: UserOutput,
    isPlannedForPublish?: boolean,
    formState?: HappeningState,
) {
    if (state !== formState) {
        return strings.save;
    }

    switch (true) {
        case canOnlySuggestHappenings(user) && [HappeningState.Concept, HappeningState.Rejected].includes(state):
            return strings.submitForApproval;
        case canOnlySuggestHappenings(user) && state === HappeningState.PartnerSuggestion:
            return strings.save;
        case state === HappeningState.Concept && !isPlannedForPublish:
        case state === HappeningState.PartnerSuggestion && !isPlannedForPublish:
        case state === HappeningState.Rejected && !isPlannedForPublish:
            return strings.publish;
        default:
            return strings.save;
    }
}

function isConceptingState(state?: HappeningState, formState?: HappeningState) {
    if (!state) {
        return true;
    }

    if (formState !== undefined && formState !== state) {
        return false;
    }

    return [HappeningState.Concept, HappeningState.PartnerSuggestion, HappeningState.Rejected].includes(state);
}

const REPEAT_MIN = 1;
const REPEAT_MAX = 366;

function findOrganizer(id: string | undefined, tags: TagCollection) {
    var tag = tags.happeningGroups.find((x) => x.id === id) || tags.partners.find((x) => x.id === id);

    return tag;
}

function supportsOnlinePayment(id: string | undefined, tags: TagCollection): boolean {
    var tag = findOrganizer(id, tags);

    if (!tag) {
        return true;
    }

    if (tag.hasApiKey) {
        return true;
    }

    return !!tag.parentTags?.some((x) => supportsOnlinePayment(x, tags));
}

function prepareForSubmission(data: FormData, tags: TagCollection, type: HappeningType) {
    const recurrence = data.recurrence?.at(0);
    const isOnLocation = data.locationType === LocationType.OnLocation;

    let modifications = { happeningType: type } as Partial<HappeningInput>;

    if (recurrence) {
        const recurrenceModifications = { ...recurrence } as RecurrenceInput;
        switch (data.recurrenceEndingType) {
            case "date":
                delete recurrenceModifications.repeatMax;
                break;
            case "count":
                delete recurrenceModifications.endDate;
                break;
        }

        switch (recurrence.recurrenceType) {
            case RecurrenceType.Monthly:
                delete recurrenceModifications.weeklyDays;
                break;
            case RecurrenceType.Weekly:
                delete recurrenceModifications.monthlyRecurrenceType;
                break;
        }

        if (isNaN(recurrence.repeatFrequency)) {
            recurrenceModifications.repeatFrequency = 0;
        }

        modifications.recurrence = [recurrenceModifications];
    }

    modifications.tagInputs = data.tagInputs?.filter((t) => !!t.id);
    modifications.partners = modifications.tagInputs?.map((t) => t.id);
    // @NOTE(Lejun) isOnline is being faced out, this can now be set to false and use locationType instead
    modifications.isOnline = false;

    if (!isOnLocation) {
        modifications.locationAddress = undefined;
        modifications.locationLatitude = undefined;
        modifications.locationLongitude = undefined;
        modifications.locationName = undefined;
    }

    if (
        !data.isFree &&
        (!supportsOnlinePayment(data.happeningGroup, tags) || type === HappeningType.PrivateHappening)
    ) {
        modifications.allowedPaymentMethods = PaymentMethod.CashOnly;
    }

    switch (data.happeningRegistrationType) {
        case HappeningRegistrationType.NoRegistration:
            modifications.requiresRegistration = false;
            modifications.maxNumberOfRegistrations = undefined;
            modifications.minPeoplePerGroup = undefined;
            modifications.maxPeoplePerGroup = undefined;
            break;
        case HappeningRegistrationType.SoloRegistration:
            modifications.requiresRegistration = true;
            modifications.minPeoplePerGroup = undefined;
            modifications.maxPeoplePerGroup = 1;
            break;
        case HappeningRegistrationType.GroupRegistration:
            modifications.requiresRegistration = true;
            modifications.maxPeoplePerGroup = data.maxPeoplePerGroup || 0;

            break;
    }

    // Sanitize the data
    if (
        !!data.targetGroup &&
        [
            UserSubset.AllOrganisations,
            UserSubset.InternalOrganisations,
            UserSubset.PartnerOrganisations,
            UserSubset.SelectedOrganisations,
        ].includes(data.targetGroup) // This checks for non-participant activities (Besloten activiteit)
    ) {
        modifications.restrictAge = false;
        modifications.minAge = undefined;
        modifications.maxAge = undefined;
        modifications.happeningTypes = [];
        modifications.targetOrganisations =
            data.targetGroup === UserSubset.SelectedOrganisations ? data.targetOrganisations : undefined;
    } else {
        modifications.targetOrganisations = undefined;
    }

    return { ...data, ...modifications };
}

export default function HappeningForm<T>({
    onDismiss,
    onSaved,
    callback,
    defaultValues,
    tags,
    storedImages,
    user,
    className,
    type,
    title,
    operation,
    happeningId,
    contact,
    registrations,
}: HappeningFormProps<T>) {
    const [updateTargetModalOpen, setUpdateTargetModalOpen] = useState(false);
    const [cancelFormOpen, setCancelFormOpen] = useState(false);

    const form = useForm<FormData>({
        defaultValues: {
            ...defaultValues,
            isFree: !defaultValues.price,
            isRecurrence: defaultValues.recurrence?.length === 1,
            recurrenceEndingType: getEndingType(defaultValues.recurrence?.at(0)),
            restrictRegistrationsDate:
                !!defaultValues.restrictRegistrationsDate ||
                defaultValues.happeningType === HappeningType.PrivateHappening,
        },
        resolver: happeningResolver as any,
    });
    // happeningState is set as the starting value and not the form value because the form value switches back and forth due to the buttons
    const happeningState = defaultValues.state ?? HappeningState.Concept;

    const {
        register,
        control,
        unregister,
        handleSubmit,
        setValue,
        setFocus,
        formState: { errors },
        watch,
    } = form;

    const [{ state, value, error }, createOrUpdateHappening] = callback();

    useEffect(() => {
        if (state === RequestState.DONE) {
            onSaved(value!);
        }
    }, [state, onSaved, value]);

    const happeningGroup = watch("happeningGroup");
    const isFree = watch("isFree");
    const requiresRegistration = watch("requiresRegistration");
    const isRecurrence = watch("isRecurrence");
    const recurrenceStartDate = watch("recurrence.0.startDate");
    const recurrenceType = watch("recurrence.0.recurrenceType");
    const recurrenceWeeklyDays = watch("recurrence.0.weeklyDays");
    const recurrenceEndDate = watch("recurrence.0.endDate");
    const recurrenceEndingType = watch("recurrenceEndingType");
    const responsibilities = watch("tagInputs");
    const locationType = watch("locationType");
    const registrationStartDate = watch("registrationStartDate");
    const registrationEndDate = watch("registrationEndDate");
    const askForSchool = watch("askForSchool");
    const isOnLocation = useMemo(() => locationType === LocationType.OnLocation, [locationType]);
    const publicationDate = watch("publicationDate");
    const displayState = watch("displayState");
    const restrictRegistrationsDate = watch("restrictRegistrationsDate");
    const restrictRegistrationsSchools = watch("restrictRegistrationsSchools");
    const restrictRegistrationsAreas = watch("restrictRegistrationsAreas");
    const targetGroup = watch("targetGroup");
    const targetOrganisations = watch("targetOrganisations");
    const hasExtraQuestions = watch("hasExtraQuestions");
    const isStandardHappening = useMemo(() => type === HappeningType.Happening, [type]);
    const isPrivateHappening = useMemo(() => type === HappeningType.PrivateHappening, [type]);
    const isExternalHappening = useMemo(() => type === HappeningType.ExternalHappening, [type]);
    const cluster = watch("cluster");
    const happeningRegistrationType = watch("happeningRegistrationType");
    const canOnlyRegisterAsGroup = watch("canOnlyRegisterAsGroup");
    // Currently selected state
    const formState = watch("state");

    const isGroupRegistration = useMemo(
        () => happeningRegistrationType === HappeningRegistrationType.GroupRegistration,
        [happeningRegistrationType],
    );

    const allowIndividuals = useMemo(() => {
        return isGroupRegistration && !canOnlyRegisterAsGroup;
    }, [isGroupRegistration, canOnlyRegisterAsGroup]);

    let initialTimeAndDates: HappeningTimeAndDateInput[] = form.getValues("timeAndDates") ?? [InitialTimeAndDates()];
    initialTimeAndDates = initialTimeAndDates.length > 0 ? initialTimeAndDates : [InitialTimeAndDates()];
    const [dateTimes, setDateTimes] = useState(initialTimeAndDates);

    const { update: updateRecurrence, replace: replaceRecurrence } = useFieldArray({ control, name: "recurrence" });

    useEffect(() => {
        if (isRecurrence) {
            const [firstDate] = form.getValues("timeAndDates") || [];
            const recurrence = form.getValues("recurrence")?.at(0);
            replaceRecurrence([
                { startDate: firstDate.sessionStart, weeklyDays: [], repeatFrequency: 0, ...recurrence },
            ]);
        } else {
            replaceRecurrence([]);
        }
    }, [form, isRecurrence, replaceRecurrence]);

    useEffect(() => {
        setValue("timeAndDates", dateTimes);

        const recurrenceArray = form.getValues("recurrence");
        if (recurrenceArray?.length === 1) {
            const [firstDate] = dateTimes;
            const [recurrence] = recurrenceArray;
            let minEndDate: Date | undefined = undefined;

            if (recurrence?.endDate) {
                if (moment(recurrence.endDate).isBefore(firstDate.sessionStart)) {
                    minEndDate = firstDate.sessionStart;
                } else if (moment(recurrence.endDate).isAfter(moment(firstDate.sessionStart).add(2, "years"))) {
                    minEndDate = moment(firstDate.sessionStart).add(2, "years").toDate();
                }
            }

            updateRecurrence(0, {
                ...recurrence,
                endDate: minEndDate || recurrence?.endDate,
                startDate: firstDate.sessionStart,
            });
        }
    }, [form, dateTimes, setValue, updateRecurrence]);

    useEffect(() => {
        switch (happeningRegistrationType) {
            case HappeningRegistrationType.NoRegistration:
                setValue("requiresRegistration", false);
                setValue("maxNumberOfRegistrations", undefined);
                unregister("maxPeoplePerGroup");
                unregister("minPeoplePerGroup");
                break;
            case HappeningRegistrationType.SoloRegistration:
            case HappeningRegistrationType.GroupRegistration:
                setValue("requiresRegistration", true);
                setValue("infoLevel", InfoLevel.Anonymous);
                if (defaultValues.happeningRegistrationType !== HappeningRegistrationType.GroupRegistration) {
                    unregister("maxPeoplePerGroup");
                    unregister("minPeoplePerGroup");
                }
                break;
        }
    }, [happeningRegistrationType, setValue, unregister, defaultValues.happeningRegistrationType]);

    useEffect(() => {
        if (isFree) {
            setValue("price", undefined);
            setValue("priceForIndividual", undefined);
            setValue("isProRata", true);
        }
    }, [isFree, setValue]);

    useEffect(() => {
        if (!isOnLocation) {
            unregister("locationAddress");
            unregister("locationLatitude");
            unregister("locationLongitude");
            unregister("locationName");
        }
    }, [isOnLocation, setValue, unregister]);

    useEffect(() => {
        if (isFree) {
            unregister("allowedPaymentMethods");
        }
    }, [isFree, unregister]);

    useEffect(() => {
        if (!hasExtraQuestions) {
            unregister("extraQuestions");
        }
    }, [hasExtraQuestions, unregister]);

    useEffect(() => {
        setFocus("title");
    }, [form, setFocus]);

    const setRestrictRegistrationsSchools = useCallback(
        (value: boolean) => {
            setValue("restrictRegistrationsSchools", value);

            if (value && !restrictRegistrationsSchools) {
                setValue("schools", []);
            }
        },
        [setValue, restrictRegistrationsSchools],
    );

    useEffect(() => {
        if (!askForSchool) {
            setValue("schools", []);
            setValue("restrictRegistrationsSchools", false);
        }
    }, [askForSchool, setValue]);

    const setAllowIndividuals = useCallback(
        (value) => {
            if (value === false) {
                setValue("priceForIndividual", undefined);
            }
            setValue("canOnlyRegisterAsGroup", !value);
        },
        [setValue],
    );

    const setRestrictRegistrationsAreas = useCallback(
        (value: boolean) => {
            setValue("restrictRegistrationsAreas", value);

            if (value && !restrictRegistrationsAreas) {
                setValue("areas", []);
            }
        },
        [setValue, restrictRegistrationsAreas],
    );

    const setRestrictRegistrationsDate = useCallback(
        (value: boolean) => {
            setValue("restrictRegistrationsDate", value);
        },
        [setValue],
    );

    const setNotVisibleForParticipation = useCallback(
        (value: boolean) => {
            setValue("targetGroup", value ? UserSubset.NotVisibleForParticipation : UserSubset.Participants);
        },
        [setValue],
    );

    useEffect(() => {
        if (!restrictRegistrationsDate) {
            setValue("registrationStartDate", undefined);
            setValue("registrationEndDate", undefined);
        }
    }, [restrictRegistrationsDate, setValue]);

    const onSave = useCallback(
        (request: FormData) => {
            const modifications = {} as Partial<HappeningInput>;
            if (
                defaultValues.recurrence?.length === 1 &&
                !isConceptingState(defaultValues.state) &&
                !request.updateTarget
            ) {
                if (isSameTimeslot(request.timeAndDates?.at(0), defaultValues.timeAndDates?.at(0))) {
                    setUpdateTargetModalOpen(true);
                    return;
                } else {
                    modifications.updateTarget = UpdateTarget.This;
                }
            }

            /**
             * In case the status is (force) updated by a ChiefEditor, the publicationDate should be removed.
             */
            if (happeningState !== HappeningState.Concept && request.state === HappeningState.Concept) {
                modifications.publicationDate = undefined;
            }

            /**
             * In case the status is (force) updated by a ChiefEditor, the publicationDate should be removed.
             */
            if (
                happeningState !== HappeningState.PartnerSuggestion &&
                request.state === HappeningState.PartnerSuggestion
            ) {
                modifications.publicationDate = undefined;
            }

            /**
             * In case the status is (force) updated by a ChiefEditor, the publicationDate should be removed.
             */
            if (happeningState !== HappeningState.Rejected && request.state === HappeningState.Rejected) {
                modifications.publicationDate = undefined;
            }

            createOrUpdateHappening(prepareForSubmission({ ...request, ...modifications }, tags, type));
        },
        [createOrUpdateHappening, defaultValues, tags, type, happeningState],
    );

    const onSaveConcept = useCallback(() => {
        setValue("isBeingPublished", false);
        setValue("publicationDate", undefined);
        setValue("state", HappeningState.Concept);
        handleSubmit(onSave as any)();
    }, [setValue, handleSubmit, onSave]);

    const onSavePartnerSuggestion = useCallback(() => {
        setValue("isBeingPublished", false);
        setValue("publicationDate", undefined);
        setValue("state", HappeningState.PartnerSuggestion);
        handleSubmit(onSave as any)();
    }, [setValue, handleSubmit, onSave]);

    const onPublish = useCallback(
        (request: FormData) => {
            setValue("isBeingPublished", true);
            setValue("publicationDate", undefined);
            setValue("state", canOnlySuggestHappenings(user) ? HappeningState.PartnerSuggestion : HappeningState.Open);
            handleSubmit(onSave as any)();
        },
        [onSave, user, handleSubmit, setValue],
    );

    const onPlannedPublish = useCallback(
        (request: FormData) => {
            setValue("isBeingPublished", true);
            handleSubmit(onSave as any)();
        },
        [onSave, handleSubmit, setValue],
    );

    const onSaveAndCancelPublish = useCallback(() => {
        setValue("publicationDate", undefined);
        handleSubmit(onSave as any)();
    }, [onSave, handleSubmit, setValue]);

    const onConfirmUpdateTarget = useCallback(
        (target: UpdateTarget) => {
            onSave({ ...(form.getValues() as any), updateTarget: target });
        },
        [onSave, form],
    );

    const setPublicationDate = useCallback(
        (value?: Date) => {
            setValue("publicationDate", value);
        },
        [setValue],
    );

    const happeningGroupOptions = useMemo(
        () =>
            getHappeningGroupOptions(operation, user, tags, defaultValues.happeningGroup).map((t) => ({
                label: t.detailedName,
                value: t.id,
            })),
        [tags, user, defaultValues.happeningGroup, operation],
    );

    const collaboratorOptions = useMemo(() => {
        let options: TagViewModel[];
        switch (true) {
            case isChiefEditor(user) || isEditor(user) || isApprover(user) || isPartner(user):
                options = [...tags.happeningGroups, ...tags.partners];

                break;
            default:
                options = [];
        }

        return options.map((t) => ({ label: t.name, value: t.id }));
    }, [user, tags.happeningGroups, tags.partners]);

    const locationOptions = useMemo(() => {
        const options = [
            { label: strings.atLocation, value: LocationType.OnLocation },
            { label: strings.onlineStream, value: LocationType.Online },
        ];

        if (isPrivateHappening) {
            options.push({ label: strings.locationAtHome, value: LocationType.AtHome });
            options.push({ label: strings.locationToBeDetermined, value: LocationType.ToBeDetermined });
        }

        return options;
    }, [isPrivateHappening]);

    const registrationTypeOptions = useMemo(() => {
        const options = [
            { label: strings.register, value: HappeningRegistrationType.SoloRegistration },
            { label: strings.walkIn, value: HappeningRegistrationType.NoRegistration },
            { label: strings.teamHappening, value: HappeningRegistrationType.GroupRegistration },
        ];

        return options;
    }, []);

    const organizerSupportsOnlinePayment = useMemo(
        () => supportsOnlinePayment(happeningGroup, tags),
        [happeningGroup, tags],
    );

    const getSecondaryButtons = useMemo(() => {
        if (happeningState !== formState) {
            return undefined;
        }

        switch (true) {
            case displayState === HappeningState.PlannedForPublication:
                return (
                    <TextButton
                        onClick={onSaveAndCancelPublish}
                        text={strings.saveAndCancelPublish}
                        buttonType="alternate"
                        isLoading={state === RequestState.LOADING}
                    />
                );
            case happeningState === HappeningState.PartnerSuggestion:
                return (
                    <TextButton
                        onClick={onSavePartnerSuggestion}
                        text={strings.savePartnerSuggestion}
                        buttonType="alternate"
                        isLoading={state === RequestState.LOADING}
                    />
                );
            case happeningState === HappeningState.Concept ||
                happeningState === HappeningState.Rejected ||
                (happeningState === HappeningState.PartnerSuggestion && !canOnlySuggestHappenings(user)):
                return (
                    <TextButton
                        onClick={onSaveConcept}
                        text={strings.saveConcept}
                        buttonType="alternate"
                        isLoading={state === RequestState.LOADING}
                    />
                );
            default:
                return undefined;
        }
    }, [
        state,
        happeningState,
        displayState,
        formState,
        user,
        onSaveAndCancelPublish,
        onSaveConcept,
        onSavePartnerSuggestion,
    ]);

    const onSubmit = useMemo(() => {
        switch (true) {
            case isConceptingState(happeningState) && displayState === HappeningState.PlannedForPublication:
                return onPlannedPublish;
            case isConceptingState(happeningState) && displayState !== HappeningState.PlannedForPublication:
                return onPublish;
            default:
                return handleSubmit(onSave as any);
        }
    }, [displayState, happeningState, onPlannedPublish, onPublish, handleSubmit, onSave]);

    const originalPublicationDate = useMemo(() => {
        if (displayState !== HappeningState.PlannedForPublication) {
            return undefined;
        }

        return defaultValues.publicationDate;
    }, [displayState, defaultValues.publicationDate]);

    const isInternal = useMemo(
        () => targetGroup !== UserSubset.Participants && targetGroup !== UserSubset.NotVisibleForParticipation,
        [targetGroup],
    );

    return (
        <FormPageLayout
            title={title}
            actionButtons={
                <div className={styles.titleActionButtons}>
                    {isStandardHappening && (
                        <Switch
                            value={isInternal}
                            setValue={(value) => {
                                const internalGroup = isChiefEditor(user)
                                    ? UserSubset.AllOrganisations
                                    : UserSubset.SelectedOrganisations;
                                setValue("targetGroup", value ? internalGroup : UserSubset.Participants);
                            }}
                            leftText={strings.internal}
                            rightText={strings.participants}
                        />
                    )}
                    <CrossButton
                        onClick={() => {
                            setCancelFormOpen(true);
                        }}
                        alt={strings.cancel}
                        className={styles.closeButton}
                    />
                </div>
            }
        >
            <div>
                {originalPublicationDate && moment(originalPublicationDate).isBefore() && (
                    <div className={styles.generalNotification}>
                        {
                            strings.formatString(
                                strings.publicationDateNotification,
                                formatAsDate(originalPublicationDate),
                                formatAsTime(originalPublicationDate),
                            ) as string
                        }
                    </div>
                )}
                <Form
                    className={`${className} ${originalPublicationDate ? styles.notificationSpace : ""}`}
                    onSubmit={onSubmit}
                    onDismiss={() => setCancelFormOpen(true)}
                    error={error}
                    submitText={getSubmitLabel(
                        happeningState,
                        user,
                        displayState === HappeningState.PlannedForPublication,
                        formState,
                    )}
                    secondaryButtons={getSecondaryButtons}
                    waitingForSubmit={state === RequestState.LOADING}
                    dropdownSubmit={
                        (happeningState === HappeningState.Concept ||
                            happeningState === HappeningState.PartnerSuggestion) &&
                        happeningState === formState &&
                        !canOnlySuggestHappenings(user) && (
                            <FloatingPublishModal
                                initialPublicationDate={publicationDate}
                                setPublicationDate={setPublicationDate}
                                onSubmit={onPlannedPublish}
                                onSecondarySubmit={onSubmit}
                                waitingForSubmit={state === RequestState.LOADING}
                            />
                        )
                    }
                    dropdownAltText={strings.openPublish}
                    dropdownTitle={strings.publishHappeningHint}
                >
                    {[HappeningState.PartnerSuggestion, HappeningState.Rejected].includes(happeningState) && (
                        <ContactDetails contact={contact} />
                    )}

                    {/* Happening Details */}
                    <Accordion title={strings.detailsHappening} collapsible open>
                        <div className={styles.titleContainer}>
                            <div className={styles.flexItemTitle}>
                                <FormField
                                    name={"title"}
                                    label={strings.titleActivity}
                                    register={register}
                                    errors={errors.title}
                                    required
                                    placeholder={""}
                                    maxLength={MAX_TITLE_LENGTH}
                                />
                            </div>
                        </div>

                        {!isInternal && (
                            <CheckboxInput
                                label={strings.notVisibleForParticipants}
                                value={targetGroup === UserSubset.NotVisibleForParticipation}
                                onChange={setNotVisibleForParticipation}
                                name="notVisibleForParticipantsCheckbox"
                            />
                        )}

                        <div className={styles.selectionContainer}>
                            <FormDataListInput
                                name={"happeningGroup"}
                                options={happeningGroupOptions}
                                label={strings.happeningGroup}
                                control={control}
                                defaultValue={form.getValues("happeningGroup")}
                                errors={errors.happeningGroup}
                                required
                                list={"happeningGroupOptions"}
                            />
                            {!organizerSupportsOnlinePayment && (
                                <div className={styles.infoSubText}>{strings.warningCashOnlyOrganisation}</div>
                            )}

                            {!isInternal && (
                                <FormMultiDataListInput
                                    name={"happeningTypes"}
                                    options={tags?.happeningTypes.map((t) => ({ label: t.name, value: t.id })) || []}
                                    label={strings.happeningTypes}
                                    control={control}
                                    value={form.getValues("happeningTypes")}
                                    required
                                    errors={errors.happeningTypes}
                                    list={"happeningTypeOptions"}
                                />
                            )}

                            {!isInternal && (
                                <div className={styles.dualInputContainer}>
                                    <FormField
                                        label={strings.targetAudience}
                                        name={"minAge"}
                                        type={"number"}
                                        register={register}
                                        required
                                        min={MIN_AGE}
                                        max={MAX_AGE}
                                        step={1}
                                        errors={errors.minAge}
                                        placeholder={""}
                                        className={styles.smallNumberInputField}
                                    />
                                    <div className={styles.smallNumberInputText}>{strings.until}</div>
                                    <FormField
                                        name={"maxAge"}
                                        type={"number"}
                                        register={register}
                                        required
                                        min={MIN_AGE}
                                        max={MAX_AGE}
                                        step={1}
                                        errors={errors.maxAge}
                                        placeholder={""}
                                        className={styles.smallNumberInputField}
                                    />
                                    <div className={styles.smallNumberInputText}>{strings.years.toLowerCase()}</div>
                                </div>
                            )}

                            {!isInternal && !isExternalHappening && (
                                <div className={styles.formCheckbox}>
                                    <FormCheckbox name={"restrictAge"} label={strings.restrictAge} control={control} />
                                </div>
                            )}

                            {!isInternal && (
                                <div className={styles.formCheckbox}>
                                    <FormCheckbox
                                        name={"isAccessible"}
                                        label={strings.setAccessible}
                                        control={control}
                                    />
                                </div>
                            )}
                        </div>

                        <div className={styles.descriptionContainer}>
                            <FormFieldMultiline
                                rows={4}
                                name={"description"}
                                label={strings.description}
                                register={register}
                                errors={errors.description}
                                required
                            />
                        </div>

                        {!isExternalHappening && (
                            <div className={styles.descriptionContainer}>
                                <div className={styles.linkContainer}>
                                    <div className={styles.largeField}>
                                        <FormField
                                            name={"link"}
                                            label={strings.linkHint}
                                            register={register}
                                            errors={errors.link}
                                            placeholder={""}
                                            filterPattern={selectHttps}
                                            replaceFilter="https://"
                                            validationPattern={{ value: basicUrlHttps, message: strings.invalidUrl }}
                                            defaultValue={form.getValues("link") && form.getValues("link")}
                                        />
                                    </div>
                                    <div className={styles.mediumField}>
                                        <FormField
                                            name={"linkLabel"}
                                            label={strings.linkLabel}
                                            register={register}
                                            placeholder={""}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className={styles.internalTagsContainer}>
                            <FormMultiDataListInput
                                name={"internalTags"}
                                options={tags?.internal.map((t) => ({ label: t.name, value: t.id })) || []}
                                label={strings.internalTags}
                                control={control}
                                value={form.getValues("internalTags")}
                                required
                                errors={errors.internalTags}
                                list={"internalTagOptions"}
                            />
                        </div>

                        <div className={styles.registrationContainer}>
                            {isStandardHappening && (
                                <div className={styles.registrationType}>
                                    <FormStringSelect
                                        name={"happeningRegistrationType"}
                                        options={registrationTypeOptions}
                                        label={strings.happeningRegistrationType}
                                        control={control}
                                        errors={errors.happeningRegistrationType}
                                        placeholder=" "
                                    />
                                </div>
                            )}

                            {isGroupRegistration && (
                                <div className={styles.individualsCheckbox}>
                                    <CheckboxInput
                                        label={strings.allowIndividuals}
                                        value={allowIndividuals}
                                        onChange={setAllowIndividuals}
                                        name="allowIndividualsCheckbox"
                                    />
                                </div>
                            )}
                        </div>

                        {isInternal && (
                            <div className={styles.formCheckbox}>
                                <FormCheckbox name={"isAccessible"} label={strings.setAccessible} control={control} />
                            </div>
                        )}

                        {!isExternalHappening && (
                            <div className={styles.registrationContainer}>
                                <div
                                    className={cx(
                                        styles.maxParticipantsContainer,
                                        isGroupRegistration && styles.groupParticipants,
                                    )}
                                >
                                    {requiresRegistration ? (
                                        <>
                                            <FormField
                                                name={"maxNumberOfRegistrations"}
                                                type={"number"}
                                                label={
                                                    isGroupRegistration
                                                        ? strings.maxRegistrationGroups
                                                        : strings.maxParticipants
                                                }
                                                register={register}
                                                min={0}
                                                errors={errors.maxNumberOfRegistrations}
                                                placeholder={""}
                                                containerClassName={cx(styles.smallInput)}
                                            />
                                            {isGroupRegistration && (
                                                <div className={styles.combinedFormFieldContainer}>
                                                    <label
                                                        htmlFor="input-minPeoplePerGroup"
                                                        className={styles.combinedFormFieldLabel}
                                                    >
                                                        {strings.membersPerGroup}
                                                    </label>
                                                    <div className={styles.dualInputContainer}>
                                                        <FormField
                                                            name={"minPeoplePerGroup"}
                                                            type={"number"}
                                                            register={register}
                                                            step={1}
                                                            errors={errors.minPeoplePerGroup}
                                                            placeholder={""}
                                                            className={styles.smallNumberInputField}
                                                            min={0}
                                                        />
                                                        <div className={styles.smallNumberInputText}>
                                                            {strings.until}
                                                        </div>
                                                        <FormField
                                                            name={"maxPeoplePerGroup"}
                                                            type={"number"}
                                                            register={register}
                                                            step={1}
                                                            errors={errors.maxPeoplePerGroup}
                                                            min={0}
                                                            placeholder={""}
                                                            className={styles.smallNumberInputField}
                                                        />
                                                        <div className={styles.smallNumberInputText}>
                                                            {strings.maximum}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <FormStringSelect
                                            name={"infoLevel"}
                                            options={[
                                                { label: strings.count, value: InfoLevel.Anonymous },
                                                { label: strings.completeAccountDetails, value: InfoLevel.Full },
                                            ]}
                                            label={strings.measureHappening}
                                            control={control}
                                            errors={errors.infoLevel}
                                            placeholder=" "
                                        />
                                    )}
                                </div>
                            </div>
                        )}

                        {requiresRegistration && !isExternalHappening && (
                            <>
                                <h3 className={styles.subTitle}>{strings.extraOptionsRegistration}</h3>
                                <div className={styles.extraInfoContainer}>
                                    <div className={styles.formCheckbox}>
                                        <FormCheckbox
                                            name={"requiresApprovalFromMarketing"}
                                            label={strings.requiresApprovalFromMarketing}
                                            value={form.getValues("requiresApprovalFromMarketing")}
                                            control={control}
                                        />
                                    </div>
                                    <div className={styles.formCheckbox}>
                                        <FormCheckbox
                                            name={"askForSchool"}
                                            label={strings.askForSchool}
                                            value={form.getValues("askForSchool")}
                                            control={control}
                                        />
                                    </div>

                                    {askForSchool && (
                                        <div className={styles.subInput}>
                                            <div className={styles.formCheckbox}>
                                                <CheckboxInput
                                                    label={strings.restrictSchools}
                                                    value={restrictRegistrationsSchools}
                                                    onChange={setRestrictRegistrationsSchools}
                                                    name="restrictRegistrationsSchoolsCheckbox"
                                                />
                                            </div>

                                            {restrictRegistrationsSchools && (
                                                <div className={styles.subInput}>
                                                    <SearchPlacesListInput
                                                        list={"schoolSearch"}
                                                        name={"schools"}
                                                        control={control}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    {isStandardHappening && (
                                        <>
                                            <div className={styles.formCheckbox}>
                                                <CheckboxInput
                                                    label={strings.restrictRegistrationsDate}
                                                    value={restrictRegistrationsDate}
                                                    onChange={setRestrictRegistrationsDate}
                                                    name="restrictRegistrationsDateCheckbox"
                                                />
                                            </div>
                                            {restrictRegistrationsDate && (
                                                <div
                                                    className={`${styles.restrictRegistrationsDateContainer} ${styles.subInput}`}
                                                >
                                                    <FormDateTimePicker
                                                        name={"registrationStartDate"}
                                                        dateTitle={strings.startDate}
                                                        control={control}
                                                        minDate={new Date()}
                                                        required
                                                        errors={errors.registrationStartDate}
                                                    />
                                                    <FormDateTimePicker
                                                        name={"registrationEndDate"}
                                                        dateTitle={strings.endDate}
                                                        control={control}
                                                        minDate={registrationStartDate}
                                                        maxDate={moment(registrationStartDate).add(1, "years").toDate()}
                                                        validationType="max1Year"
                                                        errors={errors.registrationEndDate}
                                                    />
                                                </div>
                                            )}
                                        </>
                                    )}

                                    <div className={styles.formCheckbox}>
                                        <FormCheckbox
                                            name={"hasExtraQuestions"}
                                            label={strings.extraOpenQuestion}
                                            control={control}
                                        />
                                    </div>

                                    {hasExtraQuestions && (
                                        <SubInputLayout>
                                            <FormMultiQuestionField name="extraQuestions" control={control} />
                                        </SubInputLayout>
                                    )}

                                    <div className={styles.formCheckbox}>
                                        <CheckboxInput
                                            label={strings.restrictPostalCode}
                                            value={restrictRegistrationsAreas}
                                            onChange={setRestrictRegistrationsAreas}
                                            name="restrictRegistrationsAreasCheckbox"
                                        />
                                    </div>
                                </div>
                                {restrictRegistrationsAreas && (
                                    <SubInputLayout>
                                        <SearchDataListInput list="areaSearch" name={"areas"} control={control} />
                                    </SubInputLayout>
                                )}
                            </>
                        )}
                    </Accordion>

                    {/* Media */}
                    <Accordion title={strings.media} collapsible>
                        <p className={styles.mediaPreviewDescription}>{strings.addMediaDescription}</p>
                        <FormMediaLibraryInput
                            alreadyStoredImages={storedImages}
                            className={styles.mediaPreview}
                            errors={errors.images}
                            control={control}
                            type="happening"
                        />
                    </Accordion>

                    {isInternal && (
                        <Accordion title={strings.targetAudience} collapsible>
                            <FormStringSelect
                                name={"targetGroup"}
                                options={getTargetGroupOptions(user, targetGroup)}
                                label={strings.chooseTargetGroup}
                                control={control}
                                defaultValue={form.getValues("targetGroup")}
                                errors={errors.targetGroup}
                                required
                                placeholder=" "
                            />

                            {targetGroup === UserSubset.SelectedOrganisations && (
                                <FormMultiDataListInput
                                    name={"targetOrganisations"}
                                    options={happeningGroupOptions}
                                    label={strings.onlyShowForSelectedOrganisations}
                                    control={control}
                                    value={targetOrganisations}
                                    placeholder={""}
                                    required
                                    errors={errors.targetOrganisations}
                                    list={"targetOrganisations"}
                                />
                            )}
                        </Accordion>
                    )}

                    {/* Link */}
                    <Accordion title={strings.linkHeader} collapsible>
                        <div className={styles.linkContainer}>
                            <div className={styles.largeField}>
                                <LeaderDataListInput
                                    name={"projectLeader"}
                                    label={strings.linkProjectLeader}
                                    control={control}
                                    errors={errors.projectLeader}
                                    required
                                    value={form.getValues("projectLeader")}
                                    list="leaderOptions"
                                    happeningId={happeningId}
                                />
                                <LeaderDataListInput
                                    name={"contact"}
                                    label={strings.linkContact}
                                    control={control}
                                    errors={errors.contact}
                                    required
                                    value={form.getValues("contact")}
                                    list="leaderOptions"
                                    happeningId={happeningId}
                                />
                            </div>
                            <div className={styles.largeField}>
                                <MemberDataListInput
                                    name={"projectCoLeaders"}
                                    label={strings.linkProjectCoLeaders}
                                    errors={errors.projectMembers}
                                    control={control}
                                    list="coLeaderOptions"
                                    happeningId={happeningId}
                                />
                            </div>
                            <div className={styles.largeField}>
                                <MemberDataListInput
                                    name={"projectMembers"}
                                    label={strings.linkAccounts}
                                    errors={errors.projectMembers}
                                    control={control}
                                    list="memberOptions"
                                    happeningId={happeningId}
                                />
                            </div>
                        </div>
                    </Accordion>

                    {/* Collaboration responsibilities */}
                    <Accordion title={strings.collaboration} collapsible>
                        <FormResponsibilityListInput
                            name={"tagInputs"}
                            value={responsibilities}
                            control={control}
                            title={strings.responsibilityCollaborator}
                            errors={errors.tagInputs}
                            setValue={setValue}
                            partnerOptions={collaboratorOptions}
                        />
                    </Accordion>

                    {/* Location */}
                    <Accordion title={strings.location} collapsible>
                        <div className={styles.linkContainer}>
                            <FormStringSelect
                                name={"locationType"}
                                options={locationOptions}
                                label={isPrivateHappening ? strings.chooseLocation : strings.onlineOrStream}
                                control={control}
                                errors={errors.locationType}
                                placeholder=" "
                            />
                        </div>
                        {isOnLocation && <FormAddressInput required disabled={!isOnLocation} form={form} />}
                    </Accordion>

                    {/* Plan happening */}
                    <Accordion title={strings.dateAndTime} collapsible>
                        {(isStandardHappening || isExternalHappening) && (
                            <>
                                <DateTimePickerContainer
                                    dateTimes={dateTimes}
                                    setDateTimes={setDateTimes}
                                    concept={isConceptingState(happeningState)}
                                    single={isRecurrence}
                                    errors={errors.timeAndDates}
                                />
                                {dateTimes.length === 1 && !cluster && isConceptingState(happeningState) && (
                                    <FormCheckbox
                                        name="isRecurrence"
                                        label={strings.repeatHappening}
                                        value={isRecurrence}
                                        control={control}
                                    />
                                )}
                                {isRecurrence && !isConceptingState(happeningState) && (
                                    <div className={styles.subText}>{strings.recurrenceNonConceptDateMessage}</div>
                                )}

                                {isRecurrence && isConceptingState(happeningState) && (
                                    <>
                                        <div className={styles.recurrenceFrequencyContainer}>
                                            {strings.recurrenceEvery}
                                            <div className={styles.frequency}>
                                                <FormField
                                                    name={"recurrence.0.repeatFrequency" as any}
                                                    type={"number"}
                                                    register={register}
                                                    required
                                                    min={REPEAT_MIN}
                                                    max={REPEAT_MAX}
                                                    errors={errors.recurrence?.at(0)?.repeatFrequency}
                                                    placeholder={""}
                                                />
                                            </div>
                                            <div className={styles.recurrencePeriod}>
                                                <FormStringSelect
                                                    name={"recurrence.0.recurrenceType" as any}
                                                    options={[
                                                        { label: strings.week, value: RecurrenceType.Weekly },
                                                        { label: strings.month, value: RecurrenceType.Monthly },
                                                    ]}
                                                    control={control}
                                                    required
                                                    errors={errors.recurrence?.at(0)?.recurrenceType}
                                                    placeholder={" "}
                                                />
                                            </div>
                                        </div>

                                        {recurrenceType === RecurrenceType.Weekly && (
                                            <FormCheckboxGroup
                                                name={"recurrence.0.weeklyDays"}
                                                title={strings.recurrenceOnDays}
                                                options={[
                                                    { label: strings.monday, value: DayOfWeek.Monday },
                                                    { label: strings.tuesday, value: DayOfWeek.Tuesday },
                                                    { label: strings.wednesday, value: DayOfWeek.Wednesday },
                                                    { label: strings.thursday, value: DayOfWeek.Thursday },
                                                    { label: strings.friday, value: DayOfWeek.Friday },
                                                    { label: strings.saturday, value: DayOfWeek.Saturday },
                                                    { label: strings.sunday, value: DayOfWeek.Sunday },
                                                ]}
                                                control={control}
                                                required
                                                value={recurrenceWeeklyDays}
                                                errors={errors.recurrence?.at(0)?.weeklyDays}
                                            />
                                        )}
                                        {recurrenceType === RecurrenceType.Monthly && (
                                            <FormStringSelect
                                                name={"recurrence.0.monthlyRecurrenceType" as any}
                                                options={[
                                                    {
                                                        label: strings.formatString(
                                                            strings.recurrenceMonthlyDay,
                                                            recurrenceStartDate.getDate(),
                                                        ) as string,
                                                        value: MonthlyRecurrenceType.Date,
                                                    },
                                                    {
                                                        label: strings.formatString(
                                                            strings.recurrenceMonthlyWeekDay,
                                                            getNthDayOfWeekString(recurrenceStartDate),
                                                        ) as string,
                                                        value: MonthlyRecurrenceType.WeekDayOfMonth,
                                                    },
                                                ]}
                                                label={strings.recurrenceOnDays}
                                                control={control}
                                                required={recurrenceEndingType === "date"}
                                                errors={errors.recurrence?.at(0)?.monthlyRecurrenceType}
                                                placeholder=" "
                                            />
                                        )}

                                        {!!recurrenceType && (
                                            <FormRadioGroup
                                                name={"recurrenceEndingType"}
                                                title={strings.recurrenceEndsHeader}
                                                options={[
                                                    {
                                                        label: strings.recurrenceEndsOn,
                                                        value: "date",
                                                        tail: (
                                                            <div className={styles.inlineDatepicker}>
                                                                <FormDatepicker
                                                                    name={"recurrence.0.endDate" as any}
                                                                    control={control}
                                                                    disabled={recurrenceEndingType !== "date"}
                                                                    value={recurrenceEndDate}
                                                                    minDate={recurrenceStartDate}
                                                                    maxDate={moment(recurrenceStartDate)
                                                                        .add(2, "years")
                                                                        .toDate()}
                                                                    required={recurrenceEndingType === "date"}
                                                                    errors={errors.recurrence?.at(0)?.endDate}
                                                                />
                                                            </div>
                                                        ),
                                                    },
                                                    {
                                                        label: strings.recurrenceEndsAfterOccurrences1,
                                                        value: "count",
                                                        tail: (
                                                            <>
                                                                <div className={styles.repeatMaxContainer}>
                                                                    <FormField
                                                                        name={"recurrence.0.repeatMax" as any}
                                                                        type={"number"}
                                                                        register={register}
                                                                        required={recurrenceEndingType === "count"}
                                                                        disabled={recurrenceEndingType !== "count"}
                                                                        min={REPEAT_MIN}
                                                                        max={REPEAT_MAX}
                                                                        errors={errors.recurrence?.at(0)?.repeatMax}
                                                                        placeholder={""}
                                                                    />
                                                                </div>
                                                                {strings.recurrenceEndsAfterOccurrences2}
                                                            </>
                                                        ),
                                                    },
                                                ]}
                                                control={control}
                                                required
                                                value={recurrenceEndingType}
                                                errors={errors.recurrenceEndingType}
                                            />
                                        )}
                                    </>
                                )}
                            </>
                        )}
                        {isPrivateHappening && (
                            <>
                                <div className={styles.rowContainer}>
                                    <div className={styles.compactInput}>
                                        <FormDatepicker
                                            name={"registrationStartDate"}
                                            title={strings.startDate}
                                            control={control}
                                            value={registrationStartDate}
                                            minDate={new Date()}
                                            required
                                            errors={errors.registrationStartDate}
                                        />
                                    </div>
                                    <div className={styles.compactInput}>
                                        <FormDatepicker
                                            name={"registrationEndDate"}
                                            title={strings.endDate}
                                            control={control}
                                            value={registrationEndDate}
                                            minDate={new Date()}
                                            maxDate={moment(registrationStartDate).add(1, "years").toDate()}
                                            required
                                            validationType="max1Year"
                                            errors={errors.registrationEndDate}
                                        />
                                    </div>
                                </div>

                                <div className={styles.compactInput}>
                                    <FormDurationInput
                                        name="durationInMinutes"
                                        control={control}
                                        label={strings.duration}
                                        errors={errors.durationInMinutes}
                                    />
                                </div>
                            </>
                        )}
                    </Accordion>

                    {/* Payment */}
                    <Accordion title={strings.cost} collapsible>
                        <div className={styles.linkContainer}>
                            <div className={styles.mediumField}>
                                <FormBooleanSelect
                                    name={"isFree"}
                                    options={[
                                        { label: strings.free, value: true },
                                        { label: strings.payed, value: false },
                                    ]}
                                    label={strings.happeningCostLabel}
                                    control={control}
                                />
                            </div>

                            {!isFree && (
                                <>
                                    <div className={styles.priceContainer}>
                                        <div className={styles.smallField}>
                                            <FormField
                                                name={"price"}
                                                type={"number"}
                                                label={
                                                    isExternalHappening
                                                        ? strings.priceFrom
                                                        : isGroupRegistration
                                                        ? strings.priceGroup
                                                        : strings.price
                                                }
                                                register={register}
                                                min={MIN_PRICE_VALUE}
                                                errors={errors.price}
                                                icon={EuroSymbol}
                                                placeholder={""}
                                                step={0.01}
                                            />
                                        </div>
                                        {!canOnlyRegisterAsGroup && isGroupRegistration && (
                                            <div className={styles.smallField}>
                                                <FormField
                                                    name={"priceForIndividual"}
                                                    type={"number"}
                                                    label={strings.priceIndividual}
                                                    register={register}
                                                    min={MIN_PRICE_VALUE}
                                                    errors={errors.priceForIndividual}
                                                    icon={EuroSymbol}
                                                    placeholder={""}
                                                    step={0.01}
                                                />
                                            </div>
                                        )}
                                    </div>

                                    {!isExternalHappening && (
                                        <FormStringSelect
                                            name={"allowedPaymentMethods"}
                                            defaultValue={
                                                form.getValues("allowedPaymentMethods") || PaymentMethod.CashAndOnline
                                            }
                                            options={[
                                                {
                                                    label: strings.canPayCashAndOnline,
                                                    value: PaymentMethod.CashAndOnline,
                                                },
                                                { label: strings.canPayCashOnly, value: PaymentMethod.CashOnly },
                                                { label: strings.canPayOnlineOnly, value: PaymentMethod.OnlineOnly },
                                            ]}
                                            label={strings.allowedPaymentMethods}
                                            control={control}
                                            errors={errors.allowedPaymentMethods}
                                            lockedValue={
                                                !organizerSupportsOnlinePayment || isPrivateHappening
                                                    ? strings.canPayCashOnly
                                                    : undefined
                                            }
                                            placeholder=" "
                                        />
                                    )}
                                </>
                            )}
                        </div>
                        {!isFree && !isPrivateHappening && !isExternalHappening && (
                            <div className={styles.proRataContainer}>
                                <FormCheckbox name={"isProRata"} label={strings.isProRata} control={control} />
                            </div>
                        )}
                    </Accordion>
                    {isExternalHappening && (
                        <Accordion title={strings.externalButtonOptions} collapsible>
                            <div className={styles.mediumField}>
                                <FormField
                                    name={"linkLabel"}
                                    label={strings.label}
                                    register={register}
                                    placeholder={""}
                                    maxLength={MAX_LINK_LABEL_LENGTH}
                                />
                            </div>
                            <div className={styles.largeField}>
                                <FormField
                                    name={"link"}
                                    label={strings.externalLink}
                                    register={register}
                                    errors={errors.link}
                                    placeholder={""}
                                    filterPattern={selectHttps}
                                    replaceFilter="https://"
                                    validationPattern={{ value: basicUrlHttps, message: strings.invalidUrl }}
                                    defaultValue={form.getValues("link") && form.getValues("link")}
                                />
                            </div>
                        </Accordion>
                    )}
                </Form>
            </div>
            {updateTargetModalOpen && (happeningState === HappeningState.Open || canForceEditHappening(user)) && (
                <SaveOpenHappeningModal
                    isOpen={updateTargetModalOpen}
                    onDismiss={() => setUpdateTargetModalOpen(false)}
                    onConfirm={onConfirmUpdateTarget}
                    error={error}
                    isSubmitting={state === RequestState.LOADING}
                />
            )}

            {cancelFormOpen && (
                <CancelModal isOpen={cancelFormOpen} onDismiss={() => setCancelFormOpen(false)} onSuccess={onDismiss} />
            )}
        </FormPageLayout>
    );
}

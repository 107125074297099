import LocalizedStrings from "react-localization";

let strings = new LocalizedStrings({
    nl: {
        createAccount: "Account aanmaken",
        editAccount: "Account wijzigen",
        addPartner: "Partner toevoegen",
        addAccount: "Account toevoegen",
        downloadList: "Download lijst",
        accounts: "Alle medewerkers",
        colleagues: "Medewerkers",
        applicationAdmins: "Functioneel beheerders",
        userAdmins: "Gebruikers beheerders",
        approvers: "Behandelaar medewerkers",
        marketeers: "Marketing medewerkers",
        intermediaries: "Intermediairs",
        editRightsGivenTo: "Bewerkrechten gegeven aan",
        login: "Login",
        retryLogin: "Opnieuw inloggen",
        mandatory: "verplicht",
        mandatoryField: "Dit veld is verplicht",
        open: "Open",
        close: "Sluit",
        save: "Opslaan",
        cancel: "Annuleren",
        name: "Naam",
        givenName: "Voornaam",
        familyName: "Achternaam",
        email: "E-mailadres",
        emailShort: "E-mail",
        error: "Fout",
        emailAlreadyInUse: "Dit e-mailadres is al in gebruik",
        unknownError: "Er is een onbekende fout opgetreden",
        fileSizeTooLarge: "De afbeelding is te groot. Een afbeelding mag maximaal 8 MB zijn",
        sessionBeforeNow: "De activiteit kan niet in het verleden plaatsvinden",
        sessionRegistrationBefore: "De einddatum kan niet voor de startdatum liggen",
        dateBeforeStart: "De einddatum kan niet voor de begindatum liggen",
        sessionMax1Year: "De activiteit mag maximaal één jaar ingepland staan",
        periodMax1Year: "Periode mag maximaal één jaar zijn",
        details: "Details",
        pageNotFound: "Pagina niet gevonden",
        backToHome: "Ga terug naar Home",
        home: "Home",
        role: "Rol",
        applicationAdmin: "Functioneel beheerder",
        chiefEditor: "Hoofdredacteur",
        editor: "Redacteur",
        editors: "Redacteurs",
        partner: "Partner",
        participant: "Deelnemer",
        userAdmin: "Gebruikers beheerder",
        approver: "Behandelaar",
        chiefIntermediaryRole: "Hoofdintermediair",
        intermediaryRole: "Intermediair",
        sbg: "Sportief Besteed Groep",
        userManagement: "Gebruikers",
        functionalManagement: "Functioneel beheerder",
        applications: "Financiële voorzieningen",
        applicationsRequest: "aanvraag",
        monitoring: "Monitoring",
        allIndicators: "Alle indicatoren",
        createHappening: "Activiteit aanmaken",
        updateHappening: "Activiteit wijzigen",
        detailsHappening: "Activiteit details",
        title: "Titel",
        titleActivity: "Titel activiteit",
        titleImage: "Titel foto",
        body: "Bericht",
        state: "Status",
        happeningGroup: "Organisatie",
        industries: "Sector",
        happeningType: "Interesse",
        happeningTypes: "Interesses",
        targetAudience: "Doelgroep",
        from: "Vanaf",
        fromNotifications: "Van",
        until: "t/m",
        till: "Tot",
        ageRangeDiscription: "{0} t/m {1} jaar",
        ageError: "De minimale leeftijd is groter dan de maximale leeftijd",
        groupError: "Het minimaal aantal deelnemers is groter dan het maximale aantal deelnemers",
        soloGroupError: "Het maximaal aantal deelnemers mag niet 1 zijn",
        restrictAge: "Harde leeftijdsgrens instellen",
        setAccessible: "Aangepast aanbod instellen",
        isAccessible: "Aangepast aanbod",
        recurrenceWeeklyDaysError: "Selecteer minimaal één dag",
        description: "Omschrijving",
        registerOrWalkIn: "Aanmelden of inloop",
        register: "Aanmelden",
        walkIn: "Inloop",
        teamHappening: "Groepsactiviteit",
        marketing: "Marketing",
        reserveSpots: "Reserveplekken",
        reserveList: "Reservelijst",
        limitZipcodeGroup: "Beperken op postcodes binnen groep (optioneel)",
        maxRegistrations: "Max. deelnemers",
        maxRegistrationGroups: "Max. groepen",
        maxParticipants: "Max. aantal deelnemers",
        groupParticipants: "Aantal deelnemers per groep",
        groupSize: "Max. aantal groepen",
        partnerOrganisations: "Partnerorganisatie(s)",
        authorizedArrangements: "Financiële voorzieningen",
        linkHeader: "Team",
        linkPartners: "Partner",
        linkAccounts: "Geef bewerkrechten aan",
        linkProjectCoLeaders: "Mede-organisator(en)",
        teamMembersMeasurements: "Teamleden (optioneel)",
        linkProjectLeader: "Organisator",
        linkContact: "Contactpersoon",
        add: "Voeg toe",
        remove: "Verwijder",
        location: "Locatie",
        locations: "Locatie(s)",
        locationName: "Naam locatie",
        onlineOrStream: "Op locatie of online",
        chooseLocation: "Kies locatie",
        marker: "Locatie markering",
        address: "Adres",
        atLocation: "Op locatie",
        onlineStream: "Online",
        locationToBeDetermined: "Nader te bepalen",
        locationAtHome: "Bij jou thuis",
        media: "Media",
        images: "Afbeeldingen",
        videos: "Video's",
        videoTitle: "Titel video",
        dateAndTime: "Datum en tijd",
        startTime: "Starttijd",
        endTime: "Eindtijd",
        time: "Tijd",
        date: "Datum",
        cost: "Kosten",
        category: "Categorie",
        happeningCostLabel: "Gratis of betaald",
        free: "Gratis",
        payed: "Betaald",
        price: "Prijs",
        priceFrom: "Prijs (vanaf)",
        reducedPrice: "Kortingsprijs incl. btw",
        isProRata: "Naar rato verdelen",
        proRata: "Naar rato verdeeld",
        projectLeader: "Organisator",
        contact: "Contactpersoon",
        imageUploadTitle: "Afbeelding kiezen",
        imagePreview: "Afbeelding voorbeeld",
        imageTitle: "Titel afbeelding",
        happenings: "Activiteiten",
        partnerSuggestions: "Partnersuggesties",
        myHappenings: "Mijn activiteiten",
        internalHappenings: "Besloten activiteiten",
        myApplications: "Mijn regelingen",
        allApplications: "Alle regelingen",
        newApplication: "Nieuwe regeling",
        newIndicator: "Nieuwe indicator",
        chooseArrangement: "Kies een regeling",
        allHappenings: "Alle activiteiten",
        participantActivities: "Deelnemersactiviteiten",
        individual: "Individueel",
        participants: "Deelnemers",
        review: "Review",
        happening: "Activiteit",
        concept: "Concept",
        archived: "Gearchiveerd",
        suggestion: "Suggestie",
        planned: "Ingepland",
        rejected: "Afgewezen",
        newHappening: "Nieuwe activiteit",
        newOrganisationInfo: "Nieuwe pagina",
        newRegistrationRule: "Nieuwe aanmeldregel",
        logout: "Uitloggen",
        settings: "Instellingen",
        profile: "Profiel",
        subprofiles: "Subprofielen",
        goToSubprofile: "Ga naar subprofiel",
        profilePicture: "Profielfoto",
        noHappeningsFound: "Geen activiteiten gevonden",
        noIndicatorsFound: "Geen indicatoren gevonden",
        noMeasurementsFound: "Geen metingen gevonden",
        noApplicationsFound: "Geen regelingen gevonden",
        noParticipantsFound: "Geen deelnemers gevonden",
        noUsersFound: "Geen gebruikers gevonden",
        noOrganisationInfoFound: "Geen 'In de buurt'-pagina's gevonden",
        noResultsFound: "Geen resultaten gevonden",
        sort: "Sorteer",
        seeHappening: "Activiteit inzien",
        agenda: "Agenda",
        registrations: "Registraties",
        collaboration: "In samenwerking met",
        measurementContributors: "Collega's",
        partners: "Partners",
        happeningNotFound: "Activiteit niet gevonden.",
        organisationInfoNotFound: "'In de buurt'-pagina niet gevonden.",
        indicatorNotFound: "Indicator niet gevonden.",
        measurementNotFound: "Meting niet gevonden.",
        applicationNotFound: "Regeling niet gevonden.",
        applicationTypeUnsupported: "De regeling type {0} wordt nog niet ondersteund.",
        ascending: "Oplopend",
        descending: "Aflopend",
        hide: "verberg",
        show: "toon",
        partnerGroups: "Partners",
        happeningGroups: "Organisaties",
        addProjectGroup: "Voeg project toe",
        interests: "Interesses",
        happeningsSubset: "Soort activiteit",
        categories: "Categorieën",
        organisationInfo: "In de buurt",
        internalTags: "Interne tags",
        mediaTags: "Media tags",
        tags: "Tags",
        next: "Volgende",
        previous: "Vorige",
        archive: "Archiveren",
        delete: "Verwijderen",
        clone: "Dupliceren",
        reject: "Afwijzen",
        rejectSuggestionTitle: "Suggestie afwijzen",
        unknown: "Onbekend",
        update: "Wijzigen",
        cluster: "Cluster",
        clusterButtonLabel: "Maak cluster",
        clusterName: "Cluster naam",
        clusterCover: "Cluster omslagfoto",
        addPhoto: "Foto's toevoegen",
        addVideo: "URL van YouTube of Vimeo",
        addLogo: "Logo toevoegen voor {0}",
        logo: "Logo",
        agreementMediaMarketing: "Akkoord vereist voor media van marketing (optioneel)",
        dragDropMedia: "Klik hier om te browsen of sleep je afbeeldingen",
        urlError: "Deze link wordt niet herkend als link van YouTube of Vimeo",
        basicUrlError: "Ongeldige link",
        titleTooLongError: "De titel is te lang. Gebruik maximaal 43 karakters.",
        minError: "De waarde mag niet lager dan {0} zijn.",
        minErrorIndicatorQuestions: "Voer minimaal één vraag in of voeg notities bij.",
        maxError: "De waarde mag niet hoger dan {0} zijn.",
        stepError: "Ongeldige hoeveelheid",
        inputMaxReached: "Het maximum aantal karakters van {0} is bereikt.",
        invalidPhoneNumber: "Dit is geen legitiem telefoonnummer",
        invalidEmail: "Dit is geen legitiem e-mailadres",
        invalidPostalCode: "Ongeldige postcode",
        invalidName: "Ongeldige naam",
        invalidUrl: "Ongeldige URL",
        phone: "Telefoonnummer",
        age: "Leeftijd",
        function: "Functie",
        unknownRole: "Onbekende rol",
        unknownPhoneNumber: "Onbekend",
        unknownEmail: "Onbekend",
        unknownProfession: "Onbekend",
        unknownMunicipality: "Onbekend",
        unknownDateOfBirth: "Onbekend",
        unknownPartnerGroup: "Onbekend",
        unknownHappeningGroup: "Onbekend",
        warning: "waarschuwing",
        searchByProjectLeaderName: "Zoeken (op organisator)",
        searchByMeasurerName: "Zoeken (op medewerker)",
        searchByOrganisation: "Zoeken (op organisatie)",
        searchByIndustry: "Zoeken (op sector)",
        searchByCategory: "Zoeken (op categorie)",
        searchByMediaTag: "Zoeken (op media tag)",
        searchByApplicationReviewer: "Zoeken (op intermediair)",
        searchByMunicipality: "Zoeken (op gemeente)",
        changeColorOf: "Wijzig kleur van {0}",
        search: "Zoeken",
        searchHeader: "Zoeken:",
        resetFilters: "Filters wissen",
        signups: "Aanmeldingen",
        notSignedUps: "Afmeldingen",
        years: "Jaar",
        medicalNotes: "Medische notities",
        notes: "Notities",
        paymentStatusDoneOnline: "Deelnemer heeft met iDeal {0} betaald.\nMollie ID: {1}.\nBetaalstatus: {2}.",
        paymentStatusDoneCash: "Deelnemer heeft contant betaald.",
        paymentStatusRequired: "Deelnemer heeft niet betaald.",
        paymentStatusRequiredOnline: "Deelnemer heeft niet betaald.\nMollie ID: {0}.\nBetaalstatus: {1}.",
        notApprovedForMarketing:
            "Deelnemer geeft geen toestemming voor het maken van foto’s en/of video’s die kunnen worden gebruikt voor marketingdoeleinden.",
        approvedForMarketing:
            "Deelnemer geeft toestemming voor het maken van foto’s en/of video’s die kunnen worden gebruikt voor marketingdoeleinden.",
        requiresApprovalFromMarketing:
            "Goedkeuring vragen voor het maken van beeld- en geluidsopnames voor promotiedoeleinden",
        repeatHappening: "Deze activiteit herhalen",
        editApiKey: "Wijzig API key",
        addApiKey: "Voeg Mollie API key toe",
        postalCode: "Postcode",
        school: "School",
        education: "Opleidingsniveau",
        loading: "Laden...",
        loadingSession: "Sessie laden...",
        sessionRetry: "Duurt het laden langer dan 5 seconden? Probeer het dan",
        retry: "opnieuw",
        somethingWentWrong: "Er is iets foutgegaan.",
        schoolsWentWrong:
            "Er is een fout opgetreden met het inladen van scholen, neem contact op met de systeem beheerder.",
        gender: "Geslacht",
        male: "Man",
        female: "Vrouw",
        boy: "Jongen",
        girl: "Meisje",
        other: "Anders",
        ratherNotSay: "Zeg ik liever niet",
        openProfileOf: "Open het profiel van:",
        userNotFound: "Gebruiker niet gevonden",
        restrictPostalCode: "Locatie activiteit beperken op gemeente, wijk en/of buurt",
        searchMunicipality: "Zoeken (op gemeente)",
        searchDistrict: "Zoeken (op wijk)",
        searchNeighbourhood: "Zoeken (op buurt)",
        municipality: "Gemeente",
        municipalities: "Gemeenten",
        districts: "Wijken",
        neighbourhoods: "Buurten",
        maxImageSize: "Upload minimaal één afbeelding. Een afbeelding mag maximaal 8 MB zijn",
        openDates: "Bekijk data",
        closeDates: "Dichtklappen",
        blockedTitle: "Oops!",
        blockedContent: "Deze website is niet voor jou bedoeld.",
        checkAddress: "Controleer altijd het adres op de kaart",
        notifications: "Notificaties",
        send: "Versturen",
        sendNotification: "Verstuur notificatie",
        sentNotifications: "Verstuurde notificaties",
        noNotifications: "Geen verstuurde notificaties gevonden",
        typeOfNotification: "Type notificatie",
        typeNotifications: "Type",
        typeOfActivity: "Type activiteit",
        happeningRegistrationType: "Type activiteit",
        pushnotification: "Pushnotificatie",
        makeChoice: "Maak een keuze",
        sent: "{0} verstuurd",
        sentPage: "Verstuurd",
        sentError: "Er is een fout opgetreden bij het versturen van de notificatie",
        message: "Bericht",
        messageNotifications: "Bericht",
        subject: "Onderwerp",
        type: "Type:",
        recipient: "Ontvanger",
        toNotifications: "Naar",
        allGenders: "Alle geslachten",
        restrictNotificationOnPostalCode: "Versturen naar:",
        everyone: "Iedereen",
        audienceCountText: "Met deze filters wordt de notificatie gestuurd naar {0} personen.",
        audienceHint: "Kies een ontvanger om te zien hoeveel notificaties er worden verstuurd.",
        audienceRequestFailed: "Er is een fout opgetreden bij het opvragen van het aantal ontvangers.",
        communication: "Communicatie",
        registeredParticipants: "Geregistreerde deelnemers",
        standbyList: "Reservelijst",
        favoritedParticipants: "Mensen die de activiteit gefavoriet hebben",
        allInterests: "Alle interesses",
        allHappeningGroups: "Alle organisaties",
        publish: "Publiceren",
        republish: "Herpubliceren",
        cancelled: "Geannuleerd",
        submitForApproval: "Indienen",
        badWeather: "Weersomstandigheden",
        insufficientRegistrations: "Onvoldoende registraties",
        unforseenCircumstances: "Onvoorziene omstandigheden",
        cancelReason: "Reden voor annulering",
        rejectReason: "Reden voor afwijzing",
        yes: "Ja",
        no: "Nee",
        confirmation: "Weet je het zeker?",
        measureHappening: "Activiteit meten",
        count: "Tellen",
        completeAccountDetails: "Complete deelnemergegevens",
        confirmDeleteHappening: "Weet je zeker dat je deze activiteit wilt verwijderen?",
        confirmDeleteHappeningImage: "Weet je zeker dat je deze foto wilt verwijderen van de activiteit?",
        confirmDeleteOrganisationInfoImage: `Weet je zeker dat je deze foto wilt verwijderen van de "In de buurt"-pagina?`,
        confirmDeleteMediaFromUploadedByMe: `Weet je zeker dat je deze foto wilt verwijderen uit "Door mij geüpload"? De foto blijft beschikbaar in de andere categorieën.`,
        confirmDeleteFile: "Weet je zeker dat je het bestand {0} wilt verwijderen?",
        confirmClusterHappening:
            "Weet je zeker dat je een cluster wilt maken voor deze activiteit? Dit kan je niet meer ongedaan maken.",
        mfaRequired: "MFA Verplicht",
        mfaExplenation:
            "Als gebruiker van het SBG platform ben je verplicht om een extra beveiligingslaag in te stellen voor je account. Volg de link en volg de stappen om MultiFactor Authenticatie in te stellen.",
        mfaTroubleshoot: "Zie je deze melding terwijl je MFA hebt ingesteld? Probeer dan opnieuw in te loggen.",
        resetPassword: "Reset inloggegevens",
        blockUser: "Blokkeer gebruiker",
        unblockUser: "Deblokkeer gebruiker",
        deleteUser: "Verwijder gebruiker",
        confirmBlockUser: "Weet je zeker dat je deze gebruiker wilt blokkeren?",
        confirmDeleteTitleUser: "Let op!",
        confirmDeleteBodyUser: "Weet je zeker dat je {0} permanent wilt verwijderen?",
        saveConcept: "Opslaan als concept",
        savePartnerSuggestion: "Opslaan als partner suggestie",
        inactivityTitle: "Inactief",
        inactivity:
            "Na 15 minuten inactiviteit word je automatisch uitgelogd. Ben je nog wel actief? Sluit dan deze popup.",
        sluiten: "Sluiten",
        recurrenceEvery: "Herhaal elke",
        recurrenceOnDays: "Herhalen op",
        recurrenceEndsHeader: "Eindigt",
        recurrenceEndsOn: "Op",
        recurrenceEndsAfterOccurrences1: "Na",
        recurrenceEndsAfterOccurrences2: "keer",
        week: "week",
        weeks: "week",
        month: "maand",
        months: "maanden",
        recurrenceMonthlyDay: "Maandelijks op dag {0}",
        recurrenceMonthlyWeekDay: "Maandelijks op de {0}",
        recurrenceNonConceptDateMessage:
            "Tijd en datum is uitsluitend aanpasbaar voor deze activiteit en is onderdeel van een herhaling.",
        recurrenceViewHappeningConcept: "Herhaling: in bewerking",
        recurrenceViewHappeningRepeatMax: "Herhaling: elke {0} op {1}, eindigt na {2} keer",
        recurrenceViewHappeningEndDate: "Herhaling: elke {0} op {1}, eindigt op {2}",
        monday: "Maandag",
        tuesday: "Dinsdag",
        wednesday: "Woensdag",
        thursday: "Donderdag",
        friday: "Vrijdag",
        saturday: "Zaterdag",
        sunday: "Zondag",
        first: "eerste",
        second: "tweede",
        third: "derde",
        fourth: "vierde",
        fifth: "vijfde",
        and: "en",
        the: "de",
        day: "dag",
        ordinalAffix: "e",
        organisationInfoFormTitleNew: `Pagina toevoegen aan “In de buurt”`,
        indicatorFormTitleNew: `Indicator aanmaken`,
        indicatorFormTitleDetails: `Indicator details`,
        measurementFormTitleNew: `Meting aanmaken`,
        measurementFormTitleDetails: `Meting details`,
        recurrenceUpdateSelect: "Geef aan welke activiteit je wilt annuleren.",
        recurrenceUpdateTitle: "Herhaalde activiteit bewerken",
        recurrenceUpdateTargetThis: "Deze activiteit",
        recurrenceUpdateTargetUpcoming: "Deze en volgende activiteiten",
        recurrenceUpdateTargetAll: "Alle activiteiten",
        seriesCancelSelect: "Geef aan welke lessen je wilt annuleren.",
        seriesCancelTitle: "Lessenreeks annuleren",
        seriesCancelTargetThis: "Deze les",
        seriesCancelTargetUpcoming: "Deze en volgende lessen",
        seriesCancelTargetAll: "Alle lessen",
        copyEmbedCode: "Kopieer embed code",
        confirmCopyEmbedCode: "Embed code gekopieerd",
        copyRegistrationLink: "Kopieer registratielink",
        confirmCopyLink: "Link gekopieerd",
        confirmCopyExternalPaymentId: "Mollie ID gekopieerd",
        paymentStatusEnumOpen: "Open",
        paymentStatusEnumCanceled: "Geannuleerd",
        paymentStatusEnumPending: "In afwachting",
        paymentStatusEnumAuthorized: "Geautoriseerd",
        paymentStatusEnumExpired: "Verlopen",
        paymentStatusEnumFailed: "Gefaald",
        paymentStatusEnumPaid: "Betaald",
        paymentStatusEnumRefunded: "Terugbetaald",
        happeningSubsetEnumParticipant: "Zichtbaar",
        happeningSubsetEnumInternal: "Besloten",
        happeningSubsetEnumNotVisible: "Niet zichtbaar",
        happeningSubsetEnumPrivate: "Zonder datum",
        happeningSubsetEnumPublic: "Op datum",
        happeningSubsetEnumClustered: "Cluster",
        happeningSubsetEnumAccessible: "Aangepast aanbod",
        happeningSubsetEnumOrganizedByPartner: "Partner activiteiten",
        enumActive: "Actief",
        enumInactive: "Niet actief",
        enumInternal: "Besloten",
        enumPublic: "Deelnemer",
        allowedPaymentMethods: "Betalingswijze",
        canPayCashAndOnline: "Zowel contante als iDeal betalingen",
        canPayCashOnly: "Alleen contante betalingen",
        canPayOnlineOnly: "Alleen iDeal betalingen",
        warningCashOnlyOrganisation: "Let op! Deze organisatie heeft geen ondersteuning voor iDeal betalingen",
        noSchoolsFound: "Geen scholen gevonden",
        extraOptionsRegistration: "Extra opties bij aanmelden",
        askForSchool: "Vragen op welke school de deelnemer zit",
        restrictSchools: "School beperken",
        extraOpenQuestion: "Extra vragen stellen bij aanmelding",
        extraQuestionPlaceholder: "Vul een extra vraag in",
        requiresExtraQuestion: "Invullen van extra vraag verplichten",
        configureMultipleChoice: "Vraag is een meerkeuzevraag",
        viewConfigureMultipleChoice: "Vraag heeft de volgende meerkeuze opties:",
        multipleChoiceOptions: "Meerkeuze opties",
        enumResponsibilitiesDevelopment: "Ontwikkelend",
        enumResponsibilitiesFinancing: "Financierend",
        enumResponsibilitiesSupport: "Ondersteunend",
        enumResponsibilitiesExecution: "Uitvoerend",
        enumResponsibilitiesWarrant: "Borgend",
        partnerListEmpty: "Geen partnerorganisaties toegevoegd",
        responsibilityCollaborator: "Organisatie",
        responsibilityRole: "Rol",
        restrictedSchools: "Aanmeldingen worden beperkt tot de onderstaande scholen:",
        restrictAreas: "Aanmeldingen worden beperkt tot de onderstaande gemeente, wijk en/of buurt:",
        isVisibleInApp: "Zichtbaar maken in de app",
        addressNotFound: "Adres niet gevonden",
        addAddress: "Locatie toevoegen",
        emptyLocationList: "Nog geen locaties toegevoegd",
        websiteAndSocials: "Website & socials",
        contactFullName: "Naam contactpersoon",
        contactEmail: "E-mailadres",
        contactPhone: "Telefoonnummer",
        registrationRules: "Aanmeldregels",
        createRegistrationRule: "Nieuwe aanmeldregel opstellen",
        createRegistrationRuleButton: "Toevoegen",
        registrationRuleOrganization: "Organisatie",
        registrationsAmount: "Aantal aanmeldingen",
        registrationRulePeriod: "Periode",
        createNormalHappening: "Op datum",
        createPrivateHappening: "Zonder datum",
        createPrivateHappeningFormTitle: "Activiteit aanmaken (zonder datum)",
        createExternalHappening: "Extern",
        createExternalHappeningFormTitle: "Activiteit aanmaken (extern)",
        startDate: "Startdatum aanmeldingen",
        endDate: "Einddatum aanmeldingen",
        seePrivateHappening: "Activiteit inzien (zonder datum)",
        duration: "Duur",
        hour: "uur",
        minutes: "minuten",
        registerFrom: "Aanmelden kan vanaf {0} om {1}",
        registerUntil: "Aanmelden kan tot {0} om {1}",
        statistics: "Statistieken",
        notAllowedToEditHappening: "Je hebt niet de rechten om deze activiteit te wijzigen.",
        openPublish: "Open publicatie opties",
        publishHappeningHint: "Inplannen wanneer de activiteit in de app komt",
        planPublish: "Publiceren inplannen",
        rule: "Regel",
        registrationRuleRow:
            "Voor {0} mogen deelnemers tussen {1} en {2} maximaal voor {3} gratis activiteiten aanmelden.",
        deleteRegistrationRuleWarning: "Weet je zeker dat je deze aanmeldregel wilt verwijderen?",
        saveAndCancelPublish: "Opslaan en publiceren annuleren",
        publishDirectly: "Direct publiceren",
        restrictRegistrationsDate: "Aanmeldingen beperken tot een bepaalde periode",
        publicationDateNotification: "De activiteit wordt gepubliceerd op {0} om {1}.",
        unsubscribeParticipant: "Deelnemer afmelden",
        giveReasonUnsubscribeParticipant: "Geef een reden op voor het afmelden van de deelnemer (verplicht):",
        attendanceSummary: "{0} van de {1}",
        targetGroupAllOrganisations: "Alle organisaties",
        targetGroupAllInternalOrganisations: "Alleen alle SBG-organisaties",
        targetGroupPartnerOrganisations: "Alleen alle partner organisaties",
        targetGroupSelectedOrganisations: "Zelf organisaties kiezen",
        targetGroupNotVisibleForParticipation: "Niet zichtbaar in overzicht",
        cancelChanges: "Je staat op het punt om jouw aanpassingen te annuleren.",
        selectOrganisations: "Zelf organisaties kiezen",
        selectedOrganisations: "Geselecteerde organisaties",
        allOrganisations: "Alle organisaties",
        allPartnerOrganisations: "Alle partner organisaties",
        allInternalOrganisations: "Alle interne organisaties",
        notVisibleForParticipants: "Niet zichtbaar voor deelnemers",
        notVisibleForParticipation: "Niet zichtbaar in overzicht",
        chooseTargetGroup: "Kies je doelgroep",
        onlyShowForSelectedOrganisations: "Alleen tonen voor accounts van de volgende organisaties:",
        internal: "Besloten",
        internalHappening: "Besloten activiteit",
        happeningPartOfCluster: "Activiteit is deel van een cluster",
        happeningHiddenForParticipation: "Activiteit is voor deelnemers niet zichtbaar in overzicht",
        embeddedCodes: "Embedcodes",
        generateCode: "Genereer code",
        happeningLinks: "Links naar activiteit",
        happeningLinkRegistrationForm: "Aanmeldformulier",
        happeningLinkView: "Activiteit inzien",
        happeningLinkCard: "Kaartje",
        copyLink: "Kopieer link",
        import: "Importeren",
        instructions: "Instructies",
        importGeneralInstruction: "Upload een csv-bestand met gebruikers.",
        importHeaderInstruction:
            "Zorg ervoor dat de eerste regel van je CSV de kolomnamen bevat. Als het een gebruiker bevat, wordt deze overgeslagen.",
        importColumnInstruction:
            "Er worden 7 kolommen verwacht. De verwachtte volgorde is: Voornaam, Achternaam, Email, Rol, Organisatie, Organisatie, Organisatie. De organisaties mogen leeg zijn, maar de kolom moet wel bestaan.",
        importOrganisationInstruction: "Let erop dat organisaties hoofdlettergevoelig zijn.",
        importRoleInstruction:
            "De rol van een gebruiker dient de engelse variant te zijn: Gebruikersbeheerder (UserAdmin), Functioneelbeheerder (ApplicationAdmin), Hoofdredacteur (ChiefEditor), Redacteur (Editor), Partner (Partner), Deelnemer (Participant), Marketing (Marketing), Behandelaar (Approver), Intermediair (Intermediary), Hoofdintermediair (ChiefIntermediary).",
        importFile: "CSV-Bestand",
        importSent: "Het importeren is gestart. Binnen enkele minuten ontvang je een mail met het resultaat.",
        statRegistered: "Aangemeld",
        statUnregistered: "Afgemeld",
        statRegisteredInstuif: "Ik ben er bij",
        statPresent: "Aanwezig",
        statAbsent: "Afwezig",
        statUnknown: "Onbekend",
        invalidImageTypeError: "Alleen JPEG en PNG plaatjes zijn toegestaan",
        showFilters: "Filters tonen",
        updatedDescription: "Omschrijving {0} bijgewerkt",
        updatedDescriptionError: "Er is een fout opgetreden bij het bijwerken van de {0} omschrijving",
        addDescription: "Omschrijving toevoegen",
        applicationStatusApproved: "Goedgekeurd",
        applicationStatusInReview: "Controle",
        applicationStatusNotSubmitted: "Niet ingediend",
        applicationStatusPendingAction: "Om actie gevraagd",
        applicationStatusReceived: "Ontvangen",
        applicationStatusRejected: "Afgewezen",
        applicationStatusSubmitted: "Ingediend",
        applicant: "Aanvrager",
        arrangementIntermediary: "Intermediair",
        arrangement: "Regeling",
        applicationDetailTitle: "Regeling details",
        applicationFormHeaderPersonal: "Persoonlijke informatie",
        applicationFormHeaderFinancial: "Financiële status",
        applicationFormHeaderSportClub: "Gegevens organisatie / aanbieder",
        applicationFormHeaderContribution: "Contributie voor één sportjaar",
        applicationFormHeaderOther: "Sportkleding of -attributen",
        applicationFormHeaderAdditionalInformation: "Aanvullende informatie",
        applicationFormGivenName: "Voornaam",
        applicationFormFamilyName: "Achternaam",
        applicationFormBirthdate: "Geboortedatum",
        applicationFormGender: "Geslacht",
        applicationFormCity: "Woonplaats",
        applicationFormPostalCode: "Postcode",
        applicationFormStreet: "Straat",
        applicationFormHouseNumber: "Huisnummer",
        applicationFormEmail: "E-mailadres ouder(s)",
        applicationFormPhoneNumber: "Telefoonnummer ouder(s)",
        applicationFormFinancialStatus: "Wat is de financiële status van de aanvrager?",
        applicationFormReasonOfDeficiency: "De reden waarom de aanvrager de cursus niet kan betalen",
        applicationFormHasCityPass: "Aanvrager heeft een stadspas",
        applicationFormCityPassName: "Naam stadspas",
        applicationFormCityPassNumber: "Pasnummer",
        applicationFormSportsClubName: "Naam van de aanbieder",
        applicationFormSportsClubCity: "Plaats aanbieder",
        applicationFormSportsClubActivityChild: "Wat gaat uw kind doen?",
        applicationFormSportsClubActivity: "Wat gaat u doen?",
        applicationFormContributionFee: "Contributiebedrag",
        applicationFormContributionStartDate: "Begindatum contributie",
        applicationFormContributionEndDate: "Einddatum contributie",
        applicationFormOtherNeedsChild: "Wat heeft uw kind nodig?",
        applicationFormOtherNeeds: "Wat heeft u nodig?",
        applicationFormRequiredAmount: "Winkelbedrag",
        applicationFormStore: "Naam winkel",
        applicationFormStoreAddress: "Plaats winkel",
        applicationFormAdditionalRemarks: "Zijn er nog aanvullende dingen",
        saveAndSubmitApplication: "Opslaan en indienen",
        saveAndDontSubmitApplication: "Opslaan en niet indienen",
        saveAndRequestAction: "Opslaan en om reactie vragen",
        saveAndApproveApplication: "Opslaan en goedkeuren",
        saveAndRejectApplication: "Opslaan en afwijzen",
        headerMessageNotSubmitted: "Vul hier de reden in waarom de regeling niet is ingediend",
        headerMessageRequestAction: "Welke vraag heb je aan de aanvrager?",
        headerMessageRejected: "Vul hier de reden in waarom de regeling is afgewezen",
        sendMessage: "Bericht versturen",
        patchSuccess: "Aanpassingen opgeslagen.",
        moveTeamSuccess: "Deelnemer verplaatst.",
        moveTeamError: "Er is een fout opgetreden bij het verplaatsen van de deelnemer.",
        addAppointment: "Afspraak toevoegen",
        appointer: "Medewerker",
        appointmentDate: "Datum",
        appointmentTime: "Tijd",
        appointmentLocation: "Locatie",
        searchRegistration: "Zoek aanmelding",
        assignTo: "Koppel aan...",
        assignToHappeningTitle: "Koppel {0} aan een activiteit",
        assignToHappeningDescription: `Selecteer een bestaande activiteit voor {0} en meld hem/haar af van de huidige "activiteit zonder datum".`,
        searchHappeningsHint: "Voer tenminste twee tekens in voor activiteiten...",
        assignToHappeningReason: `We hebben je overgeplaatst naar de activiteit "{0}". Veel plezier!`,
        assingToHappeningConfirmationBody1: `Weet je zeker dat je deelnemer {0} wilt overplaatsen naar de volgende activiteit?`,
        assingToHappeningConfirmationBody3: `Georganiseerd door "{0}"`,
        happeningGroupShort: "Org.",
        participantCountShort: "Deeln.",
        cancelAppointment: "Afspraak annuleren",
        cancelAppointmentReasonDescription: "Geef een reden op voor het annuleren van de afspraak (verplicht):",
        systemName: "Systeem",
        timestamp: "op {0} om {1}",
        eventHistory: "Geschiedenis",
        messageToApplicant: "Bericht aan aanvrager",
        changeLineReplace: " is gewijzigd naar ",
        changeLineRemove: " is verwijderd",
        waitingForSendNotificationMessage: `Bezig met versturen van {0} met onderwerp "{1}"..`,
        clusterChangeName: "Wijzig naam",
        clusterChangeCover: "Wijzig visual",
        clusterChangeOrganizer: "Wijzig organisatie",
        clusterShare: "Deel cluster",
        clusterAddHappening: "Toevoegen",
        clusterAltCoverDefaultText: "Visual van cluster",
        primarySchool: "Basisschool",
        secondarySchool: "Voortgezet onderwijs",
        childGivenName: "Voornaam kind",
        childFamilyName: "Achternaam kind",
        childEducationType: "Onderwijstype kind",
        grade: "Groep- of klasnummer",
        requirementsPriceLaptopComputer: "Benodigd bedrag laptop/computer",
        requirementsPriceSchoolTrip: "Benodigd bedrag schoolreis/kamp",
        computerDescription: "Omschrijving van laptop/computer",
        schoolTripDescription: "Omschrijving van schoolreis/kamp",
        participasNumber: "Participasnummer",
        whichBudget: "Welk budget?",
        schoolBudget: "Schoolspullenbudget",
        clothesBudget: "Kledingbudget",
        personalInformationChild: "Persoonlijke informatie kind",
        passNumber: "Ooievaarspasnummer",
        questionsRemarksTitleComputer: "Vragen/gegevens voor computerregeling",
        questionsRemarksTitleSchoolTrip: "Vragen/gegevens voor schoolreis/kamp",
        questionsRemarksTitleParticipas: "Vragen/gegevens voor Participas",
        schoolInfo: "Schoolgegevens",
        buyOrRentLaptop: "Koop of huur laptop/computer?",
        purchase: "Koop",
        rent: "Huur",
        childHasParticipas: "Heeft het kind een Participas?",
        childGender: "Geslacht kind",
        childDateOfBirth: "Geboortedatum kind",
        membersPerGroup: "Aantal deelnemers per groep",
        maximum: "maximaal",
        teamRegistrationName: "{0} ({1} van {2})",
        unlimited: "onbeperkt",
        teamRegistrationInsufficient: "Te weinig groepsleden",
        dateOfBirth: "Geboortedatum",
        teamsHeaderCounter: "Groepen ({0}/{1})",
        groupSizeLabel: "{0} t/m {1} maximaal",
        pricePerGroup: "{0} per groep",
        pricePerPerson: "{0} per individu",
        priceExternal: "Vanaf {0}",
        allowIndividuals: "Individuele aanmeldingen toestaan",
        priceGroup: "Prijs groep",
        priceIndividual: "Prijs individu",
        addMediaDescription: "Selecteer foto's of voeg video toe.",
        addMedia: "Voeg media toe",
        mediaLibraryModalTitle: "Media toevoegen",
        mediaLibraryModalHappeningDescription:
            "Kies hieronder de video’s en foto’s die je aan de activiteit wilt koppelen:",
        mediaLibraryModalOrganisationInfoDescription: `Kies hieronder de foto’s die je aan de "In de buurt"-pagina wilt koppelen:`,
        mediaLibraryModalClusterDescription: "Kies hieronder foto die je aan de cluster wilt koppelen:",
        addToHappening: "Voeg toe aan activiteit",
        addToOrganisationInfo: `Voeg toe aan "In de buurt"-pagina`,
        addToCluster: "Cluster wijzigen",
        searchMediaPlaceholder: "Vul hier een zoekwoord in om de content te filteren",
        searchMediaResultsHeader: `Zoekresultaten voor "{0}"`,
        mediaLibraryUncategorized: "Nog niet ingedeeld",
        mediaLibraryMyCategory: "Door mij geüpload",
        mediaLibraryAddImageHeader: "Foto’s toevoegen:",
        mediaLibraryAddVideoHeader: "Video toevoegen:",
        upload: "Upload",
        mediaUploadFileInputPlaceholder: "Kies foto",
        mediaLibraryManagementPageTitle: "Media beheren",
        mediaEditDetailModalTitle: "Media bewerken",
        mediaDeleteConfirmationBody1: "Weet je zeker dat je de foto permanent wilt verwijderen uit de media gallerij?",
        mediaDeleteConfirmationBody2: "Let op! De foto wordt gebruikt op de volgende {0} pagina's:",
        mediaDeleteConfirmationBody3: "Foto is niet in gebruik.",
        mediaDeleted: "Foto {0} is permanent verwijderd.",
        mediaUpdated: "Foto {0} is gewijzigd.",
        mediaDeletedFromUploadedByMe: `Foto {0} is verwijderd uit "Door mij geüpload".`,
        mediaDetailsLine2: "{0} - Laatst wijziging: {1}",
        mediaLibraryMinImagesErrorSingle: "Selecteer minimaal één afbeelding.",
        mediaLibraryMinImagesErrorPlural: "Selecteer minimaal {0} afbeeldingen.",
        contactPersons: "Contactpersonen",
        team: "Toewijzen aan groep",
        chooseTeam: "- Verplaats naar ... -",
        selected: "Geselecteerd",
        createNewTeam: "Nieuwe groep aanmaken",
        createNewTeamSubmit: "Groep aanmaken",
        groupName: "Groepsnaam",
        attachments: "Bijlagen",
        chooseFile: "Kies bestand...",
        file: "Bestand",
        confirmRemoveTeam: "Weet je zeker dat de groep {0} wilt verwijderen?",
        errorRemoveTeam: "Er is iets fout gegaan bij het verwijderen van de groep.",

        questionNumber: "Vraag {0}:",
        isNotShownInCommunication: "Niet getoond in communicatie",
        showInCommunication: "Tonen in communicatie",
        allMeasurements: "Alle metingen",
        measurements: "Mijn metingen",
        formIndicatorFieldName: "Naam indicator",
        formIndicatorFieldDescription: "Omschrijving",
        formIndicatorFieldOrganisation: "Organisatie",
        formIndicatorFieldOrganizer: "Organisator",
        formIndicatorFieldStartDate: "van",
        formIndicatorFieldEndDate: "tot",
        formIndicatorFieldTeam: "Team",
        formIndicatorFieldLinkTarget: "Koppel aan deelnemersprofiel of partnerorganisatie",
        formIndicatorFieldLinkHappening: "Koppel aan activiteit (optioneel)",
        formIndicatorFieldContributors: "Collega's bij de meting kunnen toevoegen",
        formIndicatorFieldCollaborators: "Samenwerkingspartners bij de meting kunnen toevoegen",
        formIndicatorHeaderLinking: "Koppeling",
        formIndicatorHeaderCollaboration: "In samenwerking met",
        formIndicatorHeaderTeam: "Team",
        formIndicatorHeaderDuration: "Looptijd",
        formIndicatorHeaderQuestionnaire: "Vragenlijst",
        formIndicatorHeaderStatistics: "Statistieken",
        formIndicatorQuestionTypeNumeric: "Getal",
        formIndicatorQuestionTypeMultipleChoice: "Meerkeuze",
        formIndicatorQuestionTypeOpen: "Open vraag",
        formIndicatorQuestionTypeHeader: "Type vraag",
        formIndicatorQuestionIsRequired: "Vraag is verplicht",
        formIndicatorQuestionHeader: "Vraag",
        formIndicatorIncludeNotes: "Notities bijvoegen",
        formAllowMultipleOptions: "Meerdere antwoorden mogelijk",
        linkTargetEnumProfile: "Deelnemersprofiel",
        linkTargetEnumPartner: "Partnerorganisatie ",
        linkTargetEnumNone: "Niet koppelen",
        actions: "Actie",
        measurementsCount: "Metingen",
        ellipsisMenuAltOpened: "Sluit acties",
        ellipsisMenuAltClosed: "Open acties",
        ellipsisMenuAltEdit: 'Bewerk "{0}"',
        ellipsisMenuAltView: 'Bekijk "{0}"',
        ellipsisMenuAltEditMeasurement: 'Bewerk meting voor "{0}"',
        ellipsisMenuAltViewMeasurement: 'Bekijk meting voor "{0}"',
        ellipsisMenuAltDelete: 'Verwijder "{0}"',
        ellipsisMenuAltDeleteMeasurement: 'Verwijder meting voor "{0}"',
        ellipsisMenuAltAddMeasurement: 'Nieuwe "{0}" meting',
        ellipsisMenuAltDuplicate: 'Dupliceer "{0}"',
        ellipsisMenuTitleEdit: "Bewerk",
        ellipsisMenuTitleView: "Bekijk",
        ellipsisMenuTitleDelete: "Verwijder",
        ellipsisMenuTitleAddMeasurement: "Nieuwe meting",
        ellipsisMenuTitleDuplicate: "Dupliceer",
        confirmDeleteIndicator: 'Weet je zeker dat je de indicator "{0}" wilt verwijderen?',
        confirmDeleteMeasurement: 'Weet je zeker dat je de meting voor indicator "{0}" wilt verwijderen?',
        yesDelete: "Ja, verwijderen",
        errorRemoveIndicator: "Er is iets fout gegaan bij het verwijderen van de indicator.",
        errorRemoveMeasurement: "Er is iets fout gegaan bij het verwijderen van de meting.",
        indicatorEndDate: "Let op: in te vullen tot en met {0}",
        measurementSaved: "Meting opgeslagen",
        moveUp: "Verplaats naar boven",
        moveDown: "Verplaats naar beneden",
        measurementGraphTitle: "Metingen per medewerker",
        measurementGraphUnitLabel: "Aantal metingen",
        noStatisticsAvailable: "Nog geen statistieken beschikbaar",
        measurer: "Medewerker",
        linkToPartner: "Koppel aan partner",
        linkToProfile: "Koppel aan deelnemer",
        suggestionDoneBy: "Ingediend door:",
        linkHint: "URL-link",
        linkLabel: "Naam URL-link",
        measurementDate: "Datum meting",
        editProfile: "Profiel bewerken",
        happeningHasNoDate: "Dit is een activiteit zonder datum",
        happeningGroupPartner: "Deze activiteit is georganiseerd door een partner",
        happeningExternal: "Deze activiteit is georganiseerd door een externe organisatie",
        externalButtonOptions: "Doorverwijsknop",
        label: "Label",
        externalLink: "Externe link",
        external: "Extern",
        externalActivity: "Doorverwijzen",
    },
});

export default strings;

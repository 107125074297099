import strings from "../../localization/strings";
import { ProfileOutput, TagType, TagViewModel, UserOutput } from "../../openapi/backend";
import Accordion from "../core/Accordion/Accordion";
import ProfileField from "../core/ProfileField/ProfileField";
import styles from "./Profile.module.scss";
import MedicalIcon from "../../assets/medical_icon.svg";
import moment from "moment";
import ProfileIcon from "../core/AvatarIcon/ProfileIcon";
import { Size } from "../../types/Size";
import { ReactNode } from "react";
import TooltipIcon from "../core/TooltipIcon/TooltipIcon";
import FlexRow from "../Layouts/FlexRow/FlexRow";
import ErrorMessage from "../core/ErrorMessage/ErrorMessage";
import { getName } from "../../utils.ts/GetName";
import { getAge } from "../../utils.ts/Profile";
import ProfileRegistrationsTable from "./ProfileRegistrationsTable";
import { getStringForGender } from "../../utils.ts/GetGenderString";

interface ProfileProps {
    profile: ProfileOutput;
    user?: UserOutput;
    error?: Response;
    EditButtons: ReactNode;
    onSelectProfile: (id: string) => void;
}

const formatPreferredTags = (tags: TagViewModel[], type: TagType) => {
    return tags
        .filter((tag) => tag.type === type)
        .map((tag) => tag.name)
        .toString();
};

export default function Profile({ profile, user, error, EditButtons, onSelectProfile }: ProfileProps) {
    return (
        <div className={styles.profile}>
            <Accordion title={strings.profile} contentClassName={styles.accordionContent} collapsible open>
                <div className={styles.staticProfileContent}>
                    <div className={styles.staticProfileIcon}>
                        <ProfileIcon profile={profile} size={Size.XLARGE} />
                    </div>
                    <ProfileField label={strings.givenName} value={profile.givenName} />
                    <ProfileField label={strings.familyName} value={profile.familyName} />
                    <ProfileField
                        label={strings.age}
                        value={`${moment().diff(profile.dateOfBirth, "years", false)} ${strings.years} (${moment(
                            profile.dateOfBirth,
                        ).format("DD/MM/YYYY")})`}
                    />
                    {profile.phoneNumber && <ProfileField label={strings.phone} value={profile.phoneNumber} />}
                    {profile.email && <ProfileField label={strings.email} value={profile.email} />}
                    <ProfileField label={strings.postalCode} value={profile.postalCode || "-"} />
                    <ProfileField label={strings.gender} value={getStringForGender(profile.gender)} />
                    <ProfileField
                        label={strings.medicalNotes}
                        value={profile.medicalNotes || "-"}
                        allowNewLines={true}
                    />
                    <ProfileField label={strings.school} value={profile.school || "-"} />
                    <ProfileField
                        label={strings.interests}
                        value={formatPreferredTags(profile.preferredFilters, TagType.HappeningType) || "-"}
                    />
                    <ProfileField
                        label={strings.happeningGroups}
                        value={formatPreferredTags(profile.preferredFilters, TagType.HappeningGroup) || "-"}
                    />
                </div>
            </Accordion>
            {user?.subprofiles && user.subprofiles.length > 0 && (
                <Accordion
                    collapsible
                    title={`${strings.subprofiles} (${user.subprofiles.length})`}
                    contentClassName={styles.accordionContent}
                    open
                >
                    <div className={styles.staticProfileContent}>
                        {user?.subprofiles.map((subprofile) => (
                            <div key={subprofile.id} className={styles.subprofile}>
                                <button className={styles.profileButton} onClick={() => onSelectProfile(subprofile.id)}>
                                    <ProfileIcon profile={subprofile} size={Size.SMALL} />
                                    {getName(subprofile)}
                                </button>
                                {subprofile.dateOfBirth && (
                                    <div className={styles.labelAge}>{`${getAge(subprofile.dateOfBirth)} ${
                                        strings.years
                                    }`}</div>
                                )}
                                {subprofile.medicalNotes && (
                                    <TooltipIcon
                                        message={subprofile.medicalNotes}
                                        icon={MedicalIcon}
                                        className={styles.tooltip}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                </Accordion>
            )}
            {profile?.contactPerson && (
                <Accordion title={strings.contact} contentClassName={styles.accordionContent}>
                    <div className={styles.staticProfileContent}>
                        <ProfileField
                            label={strings.name}
                            value={profile.contactPerson.name}
                            onClick={() => onSelectProfile(profile.contactPerson!.id)}
                        />
                        <ProfileField label={strings.phone} value={profile.contactPerson.phoneNumber} />
                        <ProfileField label={strings.email} value={profile.contactPerson.email} />
                    </div>
                </Accordion>
            )}
            {profile.registrations.length > 0 && (
                <Accordion collapsible title={`${strings.signups} (${profile.registrations.length})`} open>
                    <ProfileRegistrationsTable profile={profile} />
                </Accordion>
            )}
            <FlexRow>{EditButtons}</FlexRow>
            {error && <ErrorMessage error={error} />}
        </div>
    );
}

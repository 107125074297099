import { MeasurementInputModel } from "../models/IndicatorModels";
import { QuestionType } from "../openapi/backend";
import { MeasurementOutput, MeasurementStatus } from "../openapi/backend";
import { getName } from "./GetName";

export function getDefaultValuesFromMeasurement(measurement: MeasurementOutput | undefined) {
    if (!measurement) {
        return undefined;
    }

    const values: MeasurementInputModel = {
        ...measurement,
        answers: measurement.answers.map((answer) => ({
            questionId: answer.questionId,
            value: answer.value,
            type:
                measurement.indicator.questions.find((question) => question.id === answer.questionId)?.type ??
                QuestionType.Open,
        })),
        participantInput: {
            query: `${getName(measurement.profileTarget)}`,
            value: measurement.profileTarget?.id,
        },
        tagTargetId: measurement.tagTarget?.id,
        contributors: measurement.indicator.canAddContributors ? measurement.contributors.map(({ id }) => id) : [],
        collaborators: measurement.indicator.canAddCollaborators ? measurement.collaborators.map(({ id }) => id) : [],
    };

    return values;
}

export function isEditibleMeasurementState(state: MeasurementStatus) {
    return [MeasurementStatus.Concept, MeasurementStatus.Open].includes(state);
}

export function measurementInputToData(measurement: MeasurementInputModel) {
    return {
        ...measurement,
        answers: measurement.answers.map((answer) => ({
            questionId: answer.questionId,
            value: answer.value,
        })),
        profileTargetId: measurement.participantInput.value,
    };
}

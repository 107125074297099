import { useContext, useEffect, useMemo } from "react";
import { Control, FieldErrors, UseFormUnregister, UseFormWatch } from "react-hook-form";
import { TagsContext } from "../../../contexts/TagsContext";
import strings from "../../../localization/strings";
import { ArrangementListOutput, Role } from "../../../openapi/backend";
import getStringForRole from "../../../utils.ts/GetRole";
import FormMultiDataListInput from "../../core/Form/FormMultiDataListInput/FormMultiDataListInput";
import FormMultiSelectField from "../../core/Form/FormMultiSelectField/FormMultiSelectField";
import { FormOption } from "../../core/Inputs/SelectField/SelectField";
import { UserInput } from "../../../hooks/UserHooks";

type UserAuthorizationFields = Pick<UserInput, "roles" | "happeningGroups" | "partners" | "authorizedArrangements">;

interface AuthorizationFieldsProps<T extends UserAuthorizationFields> {
    control: Control<T, object>;
    watch: UseFormWatch<T>;
    unregister: UseFormUnregister<T>;
    errors: FieldErrors<T>;
    arrangements: ArrangementListOutput[];
}

export default function AuthorizationFields<T extends UserAuthorizationFields>(props: AuthorizationFieldsProps<T>) {
    const { control, watch, unregister, errors, arrangements } =
        props as any as AuthorizationFieldsProps<UserAuthorizationFields>;
    const { happeningGroups, partners } = useContext(TagsContext).activeTags;
    const roles = watch("roles") || [];
    const requireHappeningGroup = roles.includes(Role.Editor);
    const requirePartner = roles.includes(Role.Partner);
    const includeArrangement = roles.includes(Role.Intermediary);

    const roleOptions = useMemo(() => Object.values(Role).map((r) => ({ label: getStringForRole(r), value: r })), []);
    const happeningGroupOptions = useMemo(
        () => happeningGroups.map((group) => ({ label: group.detailedName, value: group.id })),
        [happeningGroups],
    );
    const partnerOptions = useMemo(() => partners.map((group) => ({ label: group.name, value: group.id })), [partners]);
    const arrangementOptions = useMemo<FormOption<string>[]>(
        () => arrangements.map((arrangement) => ({ label: arrangement.name, value: arrangement.id })),
        [arrangements],
    );

    useEffect(() => {
        if (requireHappeningGroup) {
            return;
        }

        unregister("happeningGroups");
    }, [requireHappeningGroup, unregister]);

    useEffect(() => {
        if (requirePartner) {
            return;
        }

        unregister("partners");
    }, [requirePartner, unregister]);

    useEffect(() => {
        if (includeArrangement) {
            return;
        }

        unregister("authorizedArrangements");
    }, [includeArrangement, unregister]);

    return (
        <>
            <FormMultiSelectField
                name={"roles"}
                options={roleOptions}
                label={strings.role}
                control={control}
                placeholder={" "}
                errors={errors.roles}
                required
            />
            {requireHappeningGroup && (
                <FormMultiDataListInput
                    name="happeningGroups"
                    options={happeningGroupOptions}
                    control={control}
                    label={strings.happeningGroup}
                    errors={errors.happeningGroups}
                    required
                    list="happeningGroups"
                />
            )}
            {requirePartner && (
                <FormMultiSelectField
                    name="partners"
                    options={partnerOptions}
                    control={control}
                    label={strings.partnerOrganisations}
                    errors={errors.partners}
                    placeholder=" "
                    required
                />
            )}
            {includeArrangement && (
                <FormMultiSelectField
                    name="authorizedArrangements"
                    options={arrangementOptions}
                    control={control}
                    label={strings.authorizedArrangements}
                    errors={errors.authorizedArrangements}
                    placeholder={" "}
                />
            )}
        </>
    );
}
